$table-thead-bg: #f9f9f9;

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $sm $md;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    font-weight: 500;
    font-size: em(12px);
    padding: $md $md;
    text-transform: uppercase;
    background-color: $table-thead-bg;
    border-bottom: 1px solid darken($table-thead-bg, 5%);

    a {
      color: $text-color;

      &:hover {
        color: lighten($text-color, 30%);
      }
    }

    .order-dec,
    .order-cres {
      position: relative;

      &:after {
        font-size: em(17px);
        position: absolute;
        top: -2px;
        margin-left: 3px;
        font-family: alianca-icons !important;
        font-style: normal!important;
        font-weight: 400!important;
      }
    }

    .order-dec {
      &:after {
        content: "\3b";
      }
    }

    .order-cres {
      &:after {
        content: "\3c";
      }
    }
  }

  > tbody > tr > td {
    padding: $sm $md;
    font-family: $font-2;
    font-size: em(13px);
    vertical-align: middle;
  }

  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}
.table-responsive-mobile {
 

  @media screen and (max-width: $screen-xs-max) {
    min-height: .01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
    overflow-x: auto;
    width: 100%;
    margin-bottom: ($line-height-computed * .75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}