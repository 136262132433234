// .main-search .select-container .dropdown-inline {
// @media screen and (max-width: $tablet) {
//   max-width: 35% !important;
// }
// }
.datepicker-container {
  /* z-index: 3 !important;*/
}
#step04{
  .form-datepicker{
    &.lg{
      .datepicker-input{
        height: 45px;
        border-radius: 4px 0 0 4px;
      }
    }
    .datepicker-input-icon{
      top: 15px;
    }
    &+.input-group-btn{
      vertical-align: top;
    }
  }
}
.form-datepicker {
  min-height: 45px;
  &.lg {
    .datepicker-container,
    .datepicker-input-container,
    .datepicker-input {
      width: 100% !important;
    }
    .datepicker-input {
      font-size: em(13px) !important;
      padding-left: 10px;
    }
    .datepicker-input-icon {
      right: 7px;
      top: 10px;
    }
  }
  .datepicker-input {
    width: 100%;
    /*@media screen and (min-width: $tablet-landscape) {
    width: 80px !important;
    }*/
    border: 0 !important;
    margin-right: 0 !important;
    margin-bottom: $xs !important;
    text-align: left !important;
    font-family: $font-2 !important;
    font-weight: normal !important;
    font-size: 11px !important;
    color: $search-content-color !important;
    border: 1px solid #cbd5e7 !important;
    box-shadow: 0 1px 1px 0 rgba(204, 215, 230, 0.5);
    border-radius: 4px;
    transition: border-color 0.2s ease;
    padding: 5px;
  }
  .form-control {
    height: 32px;
    margin-left: -7px !important;
  }
  .datepicker-input-icon {
    position: absolute;
    right: 15px;
    top: 14px;
    cursor: pointer;
    i {
      display: none;
    }
  }
}

.select-group {
  .datepicker-input {
    border: 0 !important;
    height: 32px !important;
    text-align: left !important;
    font-family: $font-2 !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: $search-content-color !important;
  }
  .form-control {
    height: 32px;
    margin-left: -7px !important;
  }
  .datepicker-input-icon {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
    i {
      display: none;
    }
  }
}

.form-datepicker,
.select-group {
  .btn {
    width: 100%;
    text-align: left;
    position: relative;
    padding: 0 10px 0 0 !important;
    .caret {
      top: 50%;
      right: $xs;
      position: absolute;
    }
  }
  .datepicker-input-container {
    position: relative;
  }
  .datepicker-container .datepicker-input-container .datepicker-input {
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }
  .datepicker-container .datepicker-calendar {
    @media screen and (max-width: $tablet) {
      right: 0;
    }
    @media screen and  (max-width: 480px) {
      width: 100% !important;
          position: fixed !important;
          top: 20% !important;
          width: 90% !important;

          left:5%;
         
    }
    .datepicker-calendar-days-container,.datepicker-calendar-day-names{
      @media screen and  (max-width: 480px) {
        width: 100% !important;
      }
    }
  }
  .datepicker-container .datepicker-calendar .datepicker-calendar-container .datepicker-calendar-days-container .day.selected,
  .datepicker-container .datepicker-calendar .datepicker-calendar-container .datepicker-calendar-days-container .day:hover:not(.disabled) {
    background: #366bad;
  }
  .datepicker-container .datepicker-calendar .datepicker-calendar-top .close svg {
    @media screen and (max-width: $tablet) {
      width: 18px;
      height: 18px;
      margin-top: 4px;
      margin-right: 4px;
    }
  }
  .datepicker-calendar-top {
    background-color: darken($search-content-color, 1%) !important;
    height: auto !important;
    padding: $sm !important;
    .year-title {
      margin-top: 0 !important;
      font-size: em(20px) !important;
    }
    button {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .datepicker-calendar-container {
    color: #2b3542 !important;
    font-family: $font-2 !important;
    background-color: #fcfcfc !important;
  }
  .datepicker-container .datepicker-calendar .datepicker-calendar-container .datepicker-calendar-days-container .day {
    color: #2b3542 !important;
    font-family: $font-2 !important;
    font-size: em(11px);
  }
  .datepicker-container .datepicker-calendar .datepicker-calendar-container .datepicker-calendar-days-container .day.selected,
  .datepicker-container .datepicker-calendar .datepicker-calendar-container .datepicker-calendar-days-container .day:hover:not(.disabled) {
    background-color: darken($search-content-color, 1%) !important;
    border: 0 !important;
    border-radius: 4px;
    color: #fff !important;
  }
  .datepicker-container .datepicker-calendar .datepicker-calendar-container .datepicker-calendar-month-section i {
    background-color: darken($search-content-color, 1%) !important;
    padding: 4px 4px 4px 2px !important;
    margin: 0 !important;
    border-radius: 4px !important;
    float: left \9;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .month-title {
    color: #2b3542 !important;
  }
  .a-button.u-is-primary {
    border: 0 !important;
    background-color: darken($search-content-color, 1%) !important;
    color: #fff !important; // color: #2b3542 !important;
    font-size: em(12px) !important;
  }
  .a-button.u-is-secondary {
    border: 0 !important;
    background-color: darken(#fcfcfc, 5%) !important; // color: #fff !important;
    color: #2b3542 !important;
    font-size: em(12px) !important;
  }
  .datepicker-input-icon:before {
    color: $search-content-color;
    font-size: em(15px);
    font-family: "alianca-icons" !important;
    content: "\6a" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.glyphicon-ok {
  &:before {
    font-family: "alianca-icons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\4a";
  }
}

.glyphicon-remove:before {
  font-family: "alianca-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\6d";
}

.swal2-modal {
  background-color: #f0f0f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  h2 {
    font-family: $font-1;
  }
  .swal2-content {
    font-family: $font-2;
  }
  .swal2-styled {
    font-family: $font-1;
    font-size: em(12px);
    border-radius: 6px;
    padding: $sm 40px;
  }
  .swal2-confirm {
    background-color: $topnav-bg-color;
  }
}

.form-group-carga {
  .form-control {
    padding: 6px 5px 6px 8px;
  }
  .input-group-addon {
    padding: 6px 5px;
    font-size: em(10px);
  }
}

.ngui-auto-complete-wrapper {
  z-index: 9;
}

.ngui-auto-complete {
  max-height: 300px;
  overflow-x: auto;
  border-color: #cbd5e7;
  border-bottom: 1px solid #cbd5e7;
  border-radius: 4px;
}

.ngui-auto-complete>ul li {
  padding: $xs $sm;
  font-family: $font-2;
  font-size: em(13px);
  cursor: pointer;
}

[hidden] {
  display: none !important;
}

.content-new-cotacao {
  .form-group {
    .alert-danger {
      font-size: em(11px);
      margin: 10px 15px -5px !important;
    }
  }
}

.table>tbody>tr>td {
  word-break: break-all;
  word-wrap: break-word;
  max-width: 400px;
}

.dropdown-item {
  &.search {
    .input-group-addon {
      display: none;
    }
    .input-group {
      width: 95%;
    }
    .form-control {
      font-size: em(12px);
      margin-left: 8px !important;
      border: 0 !important;
    }
  }
}

// FIX CNPJ Filter
.select-filter-input {
  padding-left: 0;
  padding-right: 0;
  .col-xs-12 {
    @media screen and (max-width: $tablet) {
      margin-bottom: $sm;
    }
  }
}

.select-group-cnpj {
  float: left;
  height: 60px;
  min-height: 60px;
  border-right: 1px solid #e4e4e4;
  position: relative;
  .col-xs-12 {
    padding: 0;
  }
  .caret {
    position: absolute;
    right: 0;
    top: 50%;
  }
  .control-label,
  .select-filter-label,
  hr {
    display: none;
  }
  .select-filter-input {
    width: 100%;
     // height: 60px;
    // min-height: 60px;
    padding: 0;
    .form-control {
      height: 58px;
      border: 0;
      box-shadow: none;
      font-weight: 600;
      font-size: em(11px);
      padding: 0;
    }
    .btn {
      width: 100%;
      text-align: left;
      height: 58px;
      color: #0A4795;
      font-size: em(11px);
      border: 0;
      border-radius: 0;
      padding: 0 0 0 $xs;
      box-shadow: none;
      &:active,
      &:hover {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.panel-body {
  .tp-main-message {
    max-width: 100% !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
}

.tp-main-message {
  padding-bottom: 0;
  font-size: em(13px);
  @media screen and (max-width: $tablet) {
    margin: 0 $sm;
  }
  .close {
    top: -5px;
    right: -5px;
    @media screen and (max-width: $tablet) {
      top: -10px;
      right: -10px;
    }
  }
  h4 {
    font-family: $font-1;
    font-weight: 600;
  }
  .alert-danger {
    padding: $md;
    margin-bottom: 0;
  }
  ul>li {
    // font-size: em(14px);
    padding-bottom: $xs;
  }
}

// Forms
.cnpj-dropdown {
  @media screen and (max-width: $tablet-landscape) {
    padding: 0;
  }
}

.cnpj-insc-dropdown {
  padding-right: 0;
}
