#filtro-cargas{
    background: #fff;
    padding: $lg;
    position: absolute;
    height: 100%;
    z-index: 999;
    width: 300px;
    box-shadow: 0 5px 6px #666;
    top: 230px;
    left: -300px;
    &.open{
        left: 0 !important;
        transition: 0.2s all ease-in-out;
    }
    .close{
        margin-right: -$md;
        margin-top: -$md;
    }
}