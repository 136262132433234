@charset "UTF-8";

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(#{$fonts-path}/open-sans/open-sans-v13-latin-300.eot); /* IE9 Compat Modes */
  src: local(Open Sans Light), local(OpenSans-Light)
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300.woff2) format('woff2'), /* Super Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300.woff) format('woff'), /* Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300.ttf) format('truetype'), /* Safari, Android, iOS */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300.svg#OpenSans) format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(#{$fonts-path}/open-sans/open-sans-v13-latin-300italic.eot); /* IE9 Compat Modes */
  src: local(Open Sans Light Italic), local(OpenSansLight-Italic),
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300italic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300italic.woff2) format('woff2'), /* Super Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300italic.woff) format('woff'), /* Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300italic.ttf) format('truetype'), /* Safari, Android, iOS */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-300italic.svg#OpenSans) format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(#{$fonts-path}/open-sans/open-sans-v13-latin-regular.eot); /* IE9 Compat Modes */
  src: local(Open Sans), local(OpenSans),
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-regular.woff2) format('woff2'), /* Super Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-regular.woff) format('woff'), /* Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-regular.ttf) format('truetype'), /* Safari, Android, iOS */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-regular.svg#OpenSans) format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(#{$fonts-path}/open-sans/open-sans-v13-latin-italic.eot); /* IE9 Compat Modes */
  src: local(Open Sans Italic), local(OpenSans-Italic),
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-italic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-italic.woff2) format('woff2'), /* Super Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-italic.woff) format('woff'), /* Modern Browsers */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-italic.ttf) format('truetype'), /* Safari, Android, iOS */
       url(#{$fonts-path}/open-sans/open-sans-v13-latin-italic.svg#OpenSans) format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(#{$fonts-path}/montserrat/montserrat-v7-latin-regular.eot); /* IE9 Compat Modes */
  src: local(Montserrat-Regular),
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-regular.woff2) format('woff2'), /* Super Modern Browsers */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-regular.woff) format('woff'), /* Modern Browsers */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-regular.ttf) format('truetype'), /* Safari, Android, iOS */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-regular.svg#Montserrat) format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(#{$fonts-path}/montserrat/montserrat-v7-latin-700.eot); /* IE9 Compat Modes */
  src: local(Montserrat-Bold),
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-700.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-700.woff2) format('woff2'), /* Super Modern Browsers */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-700.woff) format('woff'), /* Modern Browsers */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-700.ttf) format('truetype'), /* Safari, Android, iOS */
       url(#{$fonts-path}/montserrat/montserrat-v7-latin-700.svg#Montserrat) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'maersk_textregular';
  src: url('#{$fonts-path}/maersok/maersktext-regular-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/maersok/maersktext-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'maersk_textbold';
  src: url('#{$fonts-path}/maersok/maersktext-bold-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/maersok/maersktext-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'maersk_textlight';
  src: url('#{$fonts-path}/maersok/maersktext-light-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/maersok/maersktext-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}