// Checkbox

// Checkbox pattern 01
$checkbox-1-border: $input-border-color;
$checkbox-1-bg: rgba(255, 255, 255, 1);
$checkbox-1-checked-bg: #0773c0;
// $checkbox-1-checked-bg: rgba(64, 167, 102, 1);

$checkbox-2-checked-disable-bg: #b2b2b2;

.custom-checkbox {

  label {
    margin: 0;
    font-size: em($font-size-small);
    font-weight: 400;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 28px !important;
    cursor: pointer;
  }

  /* checkbox design */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    border-radius: $border-radius-base;
    position: absolute;
    top: -4px;
    left: 0;
    width: 22px;
    height: 22px;
    transition: opacity 200ms ease;
    -webkit-transition: opacity 200ms ease;
  }

  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    border: 1px solid $checkbox-1-border;
    box-sizing: border-box;
    background: $checkbox-1-bg;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    font-size: em(18px);
    font-family: "alianca-icons" !important;
    content: "\4a";
    text-align: center;
    vertical-align: middle;
    padding-top: 3px !important;
    padding-left: 1px !important;
    background: $checkbox-1-checked-bg;

    color: $checkbox-1-bg !important;

  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }

  /* disabled checkbox */
  [type="checkbox"]:disabled + label,
  [type="checkbox"]:disabled:not(:checked) + label:after,
  [type="checkbox"]:disabled:checked + label:after {
    box-shadow: none;
    cursor: not-allowed !important;
    opacity: .9 !important;
    color: lighten($text-color, 5%);
    border-color: darken($checkbox-2-checked-disable-bg, 2%);

    &:hover {
      border-color: lighten($checkbox-1-border, 1%);
    }
  }

  [type="checkbox"]:disabled:checked + label:after {
    cursor: not-allowed !important;
    color: lighten($text-color, 5%);
    border-color: darken($checkbox-2-checked-disable-bg, 2%);
    background: $checkbox-2-checked-disable-bg !important;
  }

  [type="checkbox"]:disabled + label {
    color: darken($checkbox-1-border, 15%);
  }
  /* accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    border: 1px dotted blue;
  }

  /* hover style just for information */
  label:hover:before {
    border: 1px solid darken($checkbox-1-border, 10%) !important;
  }
}

// .custom-radio {
//   @extend .custom-checkbox;
//   position: relative;
//
//   label {
//     position: absolute;
//     top: 3px;
//     left: 17px;
//   }
// }
