/*------------------------------------------------------------*\
    VARIABLES
\*------------------------------------------------------------*/

// Background ====================================
$body-background: #f0f0f0;

// Font
$font-1: 'Montserrat', 'Helvetica', 'Calibri', sans-serif;
$font-2: 'Open Sans', 'Helvetica', 'Calibri', sans-serif;
$font-3: 'maersk_textregular', 'Helvetica', 'Calibri', sans-serif;

// Font path ====================================
$fa-font-path: '../fonts/font-awesome/';

// Colors ====================================
$action-blue: #0061a6;
$yellow: #ffdc00;
$color-success: #18b73d;
$color-primary: #057DC8;
$color-primary-active: #021859;
$color-danger: #e73737;
$color-warning: #ffd300;
$text-color: #2b3542;

// Responsive ====================================
$desktop: 1199px;
$tablet-landscape: 991px;
$tablet: 768px;
$mobile: 420px;
$btn-primary-bg: #0061a6;

// Bootstrap vars
$nav-pills-active-link-hover-color: $color-primary;
$nav-pills-active-link-hover-bg: $color-primary;

