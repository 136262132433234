#modalVerMapa{
    .list-head{
        li{
            label{
                display: inline;
                position: relative;
                i{
                    top: 0;
                }
            }
            .n-booking{
                display: inline;
                span{
                    font-weight: bold;
                    &:first-child{
                        color: $color-primary;
                        font-size: 1.1em;
                    }
                }
            }
            font-size: 0.9em;
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            .tp-container-details{
                p{
                    display: inline;
                }
            }
        }
    }
    h5{
        color: $color-primary;
        font-size: 0.85em;
        font-family: $font-1;
        font-weight: 700;
    }
    #mapa{
        margin: $sm 0;
    }
    .timeline {
        @include clearfix();
        padding-bottom: $lg;
        >li{
            &:first-child ul li:first-child {
            .line {
              width: 50%;
              left: 50%;
              @media screen and (max-width: $tablet-landscape) {
                width: 2px !important;
                left: 0;
                height: 100%;
              }
            }
          }
          &:last-child {
            .line {
              width: 50%;
              right: 50%;
              @media screen and (max-width: $tablet-landscape) {
                display: none;
              }
            }
          }
          @include make-xs-column(12, $gutter: 0px);
          @include make-sm-column(12, $gutter: 0px);
          @include make-md-column(1.5, $gutter: 0px);

          text-align: center;
          position: relative;
          @media screen and (max-width: $tablet-landscape) {
            text-align: left;
          }
          @media screen and (min-width: 1200px) {
            width: 16%;
          }

           &.item-transbordo{
            display: none;
            @media screen and (min-width: 1024px) {
              display: block;
            }
            overflow: hidden;
            @media screen and (min-width: 768px) {
              height: 200px;
            }
            .slick-track{
              min-width: 247px;
            }
            @media screen and (min-width: 1200px) {
                width: 22% !important;

              }
            .slick-arrow{
               top: 170px;
              &::before{
                color:#797979;

              }
              &.slick-prev{
                left: 40%;
              }
              &.slick-next{
                right: 40%;
              }
            }
            .slick-slide{
              position: relative;

            }
            &-mobile{
              display: none;
              @media screen and (max-width: 1024px) {
                display: block;
              }
            }
          }


        }
        li ul {

          .line {
            height: 2px;
            background: #90a4b2;
            position: absolute;
            width: 100%;
            top: 28px;
            @media screen and (max-width: $tablet-landscape) {
              width: 2px;
              height: 100%;
            }
            &.ok{

                background: #18b73d;

               }
            &.ok-last{
                        @media screen and (min-width: $tablet-landscape) {
                                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#18b73d+48,7db909+50,90a4b2+50 */
                                background: #18b73d; /* Old browsers */
                                background: -moz-linear-gradient(left, #18b73d 48%, #7db909 50%, #90a4b2 50%) !important; /* FF3.6-15 */
                                background: -webkit-linear-gradient(left, #18b73d 48%,#7db909 50%,#90a4b2 50%) !important; /* Chrome10-25,Safari5.1-6 */
                                background: linear-gradient(to right, #18b73d 48%,#7db909 50%,#90a4b2 50%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b73d', endColorstr='#90a4b2',GradientType=1 ) !important; /* IE6-9 */
                        }
                        @media screen and (max-width: $tablet-landscape) {
                          background: #90a4b2;
                        }
              }

            &.proximo{

              background: #ff6600;

             }
          &.proximo-last{
                      @media screen and (min-width: $tablet-landscape) {
                              /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#18b73d+48,7db909+50,90a4b2+50 */
                              background: #ff6600; /* Old browsers */
                              background: -moz-linear-gradient(left, #ff6600 48%, #ff6600 50%, #ff6600 50%) !important; /* FF3.6-15 */
                              background: -webkit-linear-gradient(left, #ff6600 48%,#ff6600 50%,#ff6600 50%) !important; /* Chrome10-25,Safari5.1-6 */
                              background: linear-gradient(to right, #ff6600 48%,#ff6600 50%,#ff6600 50%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6600', endColorstr='#ff6600',GradientType=1 ) !important; /* IE6-9 */
                      }
                      @media screen and (max-width: $tablet-landscape) {
                        background: #ff6600;
                      }
            }
          }
          .circle {
            width: 16px;
            height: 16px;
            background: #90a4b2;
            position: absolute;
            left: 50%;
            margin-left: -$sm;
            border-radius: $md;
            top: $md;
            z-index: 100;
            @media screen and (max-width: $tablet-landscape) {
              left: 3px;
            }
          }
          .circle-success {
            width: 16px;
            height: 16px;
            background: #18b73d;
            position: absolute;
            left: 50%;
            margin-left: -$sm;
            border-radius: $md;
            top: $md;
            z-index: 100;
            @media screen and (max-width: $tablet-landscape) {
              left: 3px;
            }
          }

          .circle-proximo {
            width: 16px;
            height: 16px;
            background: #ff6600;
            position: absolute;
            left: 50%;
            margin-left: -$sm;
            border-radius: $md;
            top: $md;
            z-index: 100;
            @media screen and (max-width: $tablet-landscape) {
              left: 3px;
            }
          }

          .atrasado {
            color: red;
          }

          .realizado {
            color: #18b73d;
          }

          .proximo {
            color: #ff6600 !important;
          }

          .nao-visitado {
            color: #90a4b2 !important;
          }

          .title {
            font-size: em(10px);
            text-transform: uppercase;
            font-weight: 600;
            color: $color-success;
            margin-top: 50px;
            @media screen and (max-width: $tablet-landscape) {
              margin-top: $md;
            }
          }
          .local {
            font-size: em(11px);
            color: $color-primary;
            font-weight: 500;
          }
          p {
            font-size: em(11px);
            margin-bottom: 0;
          }
          .title,
          .local,
          p {
            @media screen and (max-width: $tablet-landscape) {
              margin-left: $md;
            }
          }
        }
      }
}
