.tp-contact {
  $contact-title-color: #002353;
  position: relative;

  &.main-maps {
    background-image: url(#{$img-path}/bg-contato.jpg);
    background-color: #1d2c4d;
    background-size: inherit;
    background-position: top right;
    background-repeat: no-repeat;

    @media screen and (max-width: $tablet-landscape) {
      background-size: cover;
    }
  }

  .page-title {
    font-size: em(36px);
    margin: 35px 0 0;
    text-align: center;
  }

  .title {
    font-weight: lighter;
    font-size: em(16px);
    font-weight: bold;
    color: $contact-title-color;
    margin: 0;

    @media screen and (max-width: $tablet-landscape) {
      font-size: em(12px);
    }
  }

  .title-contact-info {
    @extend .title;
    color: #fff;
  }

  .panel {
    position: relative;
    top: -80px;
  }

  .panel-body {
    padding: 40px 0 $lg $lg;
    position: relative;

    @media screen and (max-width: $tablet-landscape) {
      padding: 40px $lg;
    }
  }

  .loading-contact {
    position: absolute;
    top: 15px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .control-label {
    font-size: em(12px);
  }

  .highlights {
    width: 100%;
    padding: 0 0 100px;
    border-top-right-radius: 4px;
    background-color: $action-blue;

    @media screen and (max-width: $tablet-landscape) {
      padding: 0;
    }
  }

  .btn-action {
    border-radius: 20px;
    padding: $sm 50px;

    .icon {
      font-size: em(18px);
      position: relative;
      top: 3px;
    }
  }
}

.contact-info {
  $contact-info-text-color: #fff;
  $contact-link-color: #ffdc00;

  @include clearfix();
  position: relative;
  margin-right: $lg;

  .title {
    font-size: em(13px);
    margin-bottom: $sm;
    color: $contact-info-text-color;
  }

  .phone {
    font-size: em(15px) !important;
    margin-top: 3px !important;
  }

  .icon {
    position: absolute;
    font-size: em(20px);
    color: $contact-info-text-color;
    left: 0;
  }

  .link {
    color: $contact-link-color;
    font-size: em(15px) !important;

    &:hover,
    &:focus {
      color: darken($contact-link-color, 20%);
    }
  }

  .content {
    margin: 0 0 35px 35px;
    float: left;

    p {
      font-family: $font-1;
      color: $contact-info-text-color;
      font-weight: 500;
      font-size: em(12px);
      margin: 0;
    }
  }
}
