#modalFinalizarNota {
  .modal {
    padding-right: 0 !important;
  }
  .alert {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }
  .modal-body {
    background-color: #f4f8fb;
  }
  .panel {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }
  .panel-body .form-control-feedback.icon {
    top: 7px;
    right: 17px;
  }
  .panel-body .form-group .control-label {
    padding-top: 10px;
    height: 45px;
  }
  .custom-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #7486a5;
    color: #ffffff;
  }
  .label-index-nf {
    height: 40px !important;
  }
  .input-key-nf {
    height: 35px;
  }
  .btn-add-nf {
    height: 46px;
  }
  .btn-remove-nf {
    height: 35px;
    @media (max-width: $mobile) {
      min-width: 40px;
      width: 100%;
    }
    @media (min-width: $mobile) and (max-width: $tablet-landscape) {
      width: 100%;
    }
  }
  #header-nf {
    .item {
      &-provedor {
        @include make-xs-column(12, $gutter: 15px);
        @include make-sm-column(12, $gutter: 15px);
        @include make-md-column(4.5, $gutter: 15px);
        @include make-lg-column(4.5, $gutter: 15px);
      }
      &-ordem-servico {
        @include make-xs-column(12, $gutter: 15px);
        @include make-sm-column(12, $gutter: 15px);
        @include make-md-column(2.3, $gutter: 15px);
        @include make-lg-column(2.3, $gutter: 15px);
      }
      &-servico {
        @include make-xs-column(12, $gutter: 15px);
        @include make-sm-column(12, $gutter: 15px);
        @include make-md-column(2.3, $gutter: 15px);
        @include make-lg-column(2.3, $gutter: 15px);
      }
      &-programado {
        @include make-xs-column(12, $gutter: 15px);
        @include make-sm-column(12, $gutter: 15px);
        @include make-md-column(1.75, $gutter: 15px);
        @include make-lg-column(1.75, $gutter: 15px);
      }
      &-status {
        @include make-xs-column(12, $gutter: 15px);
        @include make-sm-column(12, $gutter: 15px);
        @include make-md-column(1, $gutter: 15px);
        @include make-lg-column(1, $gutter: 15px);
      }
    }
    p {
      font-size: 0.85em;
    }
  }
  #informacoesNF {
    .title {
      font-size: 0.75em;
    }
  }
  .input-icon-xs {
    .form-control {
      height: 35px;
    }
    .form-control {
      padding-right: 20px;
      font-size: 0.75em;
      padding: $xs;
    }
    .icon {
      top: 10px;
      right: 8px;
      font-size: 0.95em;
    }
  }
  .bg-blue {
    background: #f4f8fb;
  }
  .bg-icon-user {
    background: $action-blue;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: #fff;
    line-height: 32px;
    vertical-align: middle;
    text-align: center;
    float: left;
    margin-right: $xs;
  }
  .bg-icon-file {
    background: #678ba5;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: #fff;
    line-height: 32px;
    vertical-align: middle;
    text-align: center;
    float: left;
    margin-right: $xs;
  }
  .provedor {
    p {
      color: $color-primary;
      font-weight: 700;
      font-size: 0.8em;
      padding-left: 36px;
    }
  }
  #header-agendar_entrega {
    $agendamento-header-bg-color: #7486a5;
    @include clearfix();
    background-color: $agendamento-header-bg-color;
    .control-label {
      line-height: normal;
      color: #fff;
      font-size: em(11px);
    }
  }
  ul {
    li {
      padding: 15px $sm $sm;
      &.col_ae {
        &_indicador {
          @include make-xs-column(12, $gutter: 15px $sm $sm);
          @include make-sm-column(4, $gutter: 15px $sm $sm);
        }
        &_checked {
          @include make-xs-column(4, $gutter: 15px $sm $sm);
          @include make-sm-column(0.5, $gutter: 15px $sm $sm);
        }
        &_justificativa-provedor {
          @include make-xs-column(8, $gutter: 15px $sm $sm);
          @include make-sm-column(7.5, $gutter: 15px $sm $sm);
        }
      }
      .icon {
        top: 12px;
      }
    }
  }
  .tp-agendamento-content {
    .col_ae_conteiner {
      label {
        font-family: $font-2;
        font-weight: 400;
        font-size: em(13px);
      }
      span {
        font-family: $font-2;
        font-weight: 400;
        font-size: em(11px);
      }
    }
    &.col_ae {
      &_id {
        border-right: 1px solid $input-border-color;
      }
    }
  }
}
