// .panel-perfil{
// 	padding: 0 $lg;
//
// 	.table{
// 		margin-top: $lg;
// 	}
// }

.panel-perfil-settings {
	.control-switch {
		position: relative;
		float: left;
		margin-right: $lg;

		.control-label {
			font-family: $font-2;
			text-transform: none;
			position: relative;
			top: -10px;
			margin-left: $xs;
			color: $text-color;
		}
	}

	.control-switch-sm {
		position: relative;
		float: left;
		margin: 0 $xs $sm 0;
		min-width: 235px;

		.control-label {
			font-family: $font-2;
			text-transform: none;
			position: relative;
			top: -5px;
			margin-left: 3px;
			color: $text-color;
		}
	}
}
