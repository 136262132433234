.form-group-upload{
    height: 45px;
    border: 1px solid $input-border-color;
    color: $input-text-color;
    font-size: em(13px);
    font-family: $font-2;
    background-color: $input-bg-color;
    box-shadow: 0px 1px 1px 0px rgba(204, 215, 230, 0.5);
    border-radius: 4px;
    transition: border-color .2s ease;
    -webkit-transition: border-color .2s ease;
    outline: 0;
    position: relative;

    input{
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
    }
    button{
        position: absolute;
        right: 5px;
        top: 5px;
    }
}