#panel-parametrizacoes-navios{
	@include space(margin, top,$md);


}
.box-slings{
	.row {
		@include space(margin, top,$md);
	}
}

.panel-settings {
	.nav-settings-menu {
		li {
			width: 100%;
			margin-left: 0;
		}

		a {
			padding: 15px $md;
			margin: 0 $sm 0 0;
		}
	}
}
.selectIdenSearch{
	.dropdown-inline {
		width: 100%;
	}
	&.select-group {
		.btn-default {
			@include text-overflow();
			font-family: $font-2;
			font-size: em(13px);
			font-weight: bold;
			color: $search-content-color;
			background-color: transparent;
			border: 0;
			outline: none !important;
			box-shadow: inherit;
			padding: 0; // @media screen and (max-width: $tablet-landscape) {
			//   font-size: em(11px);
			// }
			&:hover,
			&:focus {
			  color: lighten($search-content-color, 15%);
			  background-color: transparent;
			  border: 0;
			  outline: none !important;
			  box-shadow: inherit;
			}
		  }
		  .dropdown-menu>.selected>a {
			font-weight: bold;
			.check-mark {
			  font-family: alianca-icons!important;
			  font-style: normal!important;
			  font-weight: 400!important;
			  position: relative;
			  top: -1px;
			  &:before {
				content: "\4a";
			  }
			}
		  }
		  .dropdown-menu {
			.form-control {
			  border: 1px solid lighten($search-label-color, 20%);
			}
		  }
		  .dropdown-menu>li>a {
			font-family: $font-2;
			font-size: em(13px);
			padding: $xs $sm;
		  }
		  .filter-option {
			@include text-overflow();
			width: 95% !important;
		  }
		  .dropdown-toggle .caret {
			right: 0;
		  }
		position: relative;
		width: 200px;
		@media screen and (min-width: $desktop) {
		/*max-width: 200px;*/
		}
		padding: 3px $sm 0 $sm;
		display: table-cell;
		vertical-align: middle;
		//border-right: 1px solid lighten($search-label-color, 35%);
		.form-control-loading {
		  right: 2% !important;
		  top: 35% !important;
		  background-color: #ffffff;
		  height: 30px;
		  z-index: 999999;
		}
		&.sm {
		  width: 130px;
		  max-width: 130px;
		}
		&.lg {
		  width: 400px;
		  max-width: 400px;
		}
		@media screen and (max-width: $mobile) {
		  display: inline-block;
		/*  width: 50%;
		  max-width: 100%;*/
		  border-right: 0;
		}
		@media (min-width: $mobile) and (max-width: $tablet) {
		  display: inline-block;
		  width: 100%;
		  max-width: 100%;
		}
		.control-label {
		  font-family: $font-2;
		  font-size: em(13px);
		  font-weight: 500;
		  color: $search-label-color;
		  display: block;
		  margin: $xs 0 0;
		}
		.form-control {
		  font-family: $font-2;
		  font-size: em(13px);
		  height: 32px;
		  font-weight: bold;
		  color: $search-content-color;
		  background-color: transparent;
		  border: 0;
		  outline: none !important;
		  box-shadow: inherit;
		  padding: 0;
		}
	  }
}
