/*------------------------------------------------------------*\
    TYPOGRAPHY
\*------------------------------------------------------------*/

$font-1-color: $text-color;
$link-color: rgba(12, 142, 255, 1);

body {
  @include font-smoothing();
  font-weight: 400;
  font-size: $font-size-base;
  font-family: $font-1;
  color: $font-1-color;
  letter-spacing: .3px;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

small {
  font-family: $font-2;
  font-weight: 600;
  font-size: em(11px);
}

a {
  color: $link-color;
  outline: none;

  &:hover,
  &:focus {
    color: rgba(78, 124, 178, .74);
    text-decoration: none;
  }
}

.text-overflow {
  @include text-overflow();
}

.text-block {
  overflow: inherit !important;
  text-overflow: inherit !important;
  white-space: inherit !important;
}

.page-title {
  font-family: $font-1;
  font-size: em(32px);
  font-weight: 500;
  color: #fff;
  margin: $md 0 $lg;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(33, 73, 97, 0.5);

  @media screen and (max-width: $tablet-landscape) {
  font-size: em(22px);
  margin: 0 0 $md;
  }
}

.admin-subtitle {
  margin-bottom: $md;
}

.login-message {
  font-size: em(22px) !important;
  color: $font-1-color !important;
  margin-bottom: 0;
}

.modal-body-message {
  font-family: $font-2;

  .control-label {
    font-weight: 700;
  }
}

.title-noresults {
  font-size: em(19px);
  line-height: 1.5em;
  margin: 30px 0;
  padding-top: 25px;
  border-top: 1px solid darken($body-background, 8%);

  @media screen and (max-width: $tablet-landscape) {
    font-size: em(18px);
  }
}

.title-empty {
  font-size: em(18px);
  line-height: 1.5em;
  margin-bottom: $lg;

  @media screen and (max-width: $tablet-landscape) {
    font-size: em(16px);
  }
}

.page-subtitle {
  margin: 0;

  @media screen and (max-width: $tablet-landscape) {
    text-align: center;
    margin: 0 0 15px;
  }
}
.text-muted {
  color: #777 !important;
}
.text-primary {
  color: #337ab7 !important;
}
.text-success {
  color: #18b73d !important;
}
.text-danger {
  color: #b71818 !important;
}
.text-warning {
  color: #8a6d3b !important;
}

.input-group-addon {
  font-size: em(12px);
}

p.blocked {
  overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
}

.control-title {
  font-family: $font-1;
  font-size: em(13px);
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(78, 124, 178, .74);
  line-height: inherit !important;
}

.no-line-height {
  line-height: 25px !important;
}

.filter-group_name {
  font-size: em(11px) !important;
}

.collapse-details-title {
  font-family: $font-1;
  font-size: em(13px);
  font-weight: 500;
  color: lighten($color-primary, 10%);
  margin-bottom: $xs;
  padding: 0 15px;
}

.control-user-info label {
  font-family: $font-2;
  font-size: em(12px);
  font-weight: 400;
  float: left;
  margin-right: $md;
}

.panel-title {
  font-family: $font-1;
  font-weight: 500;
  font-size: em(14px);
}


.control-content {
  font-family: $font-1;
  font-size: em(13px);
  font-weight: 400;
}
