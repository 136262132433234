
.content-new-cotacao,
.content-new-booking {

	.page-loader {
		min-height: 460px;
	}

	h4 {
		padding: 0 $md;
	}

}
.content-new-cotacao{
	.tooltip.right {
		margin-left: 3px;
		padding: 0 5px;
		width: 320px;
	}
}
.content-new-cotacao,
.content-new-booking {
	// padding-bottom: 0 !important;
	
	label {
		line-height: 45px;
		vertical-align: middle;

		@media screen and (max-width: $tablet-landscape) {
			line-height: 30px;
		}

		&.no-line-middle {
			line-height: inherit;
			line-height: 1.3em;
		}
	}

	.form-group {
		@include clearfix();
	}

	.line-middle {
		line-height: 45px;
		vertical-align: middle;
	}
}

.branco {
	background: #ffffff;
}

.nav-steps {
	@include clearfix();
	width: 100%;
	background: lighten($gray-base, 90%);

	&.booking {
		ul {
			li {
				text-align: center;
				@include make-xs-column(3, $gutter: 0);
				@include make-sm-column(3, $gutter: 0);
				@include make-md-column(3, $gutter: 0);
			}
		}
	}

	.container {
		@media screen and (max-width: $mobile) {
			padding: 0;
		}
	}

	ul {
		li {
			text-align: center;
			@include make-xs-column(4, $gutter: 0);
			@include make-sm-column(4, $gutter: 0);
			@include make-md-column(4, $gutter: 0);

			a {
				display: block;
				font-size: em(13px);
				border: 1px solid lighten($gray-base, 85%);
				border-top: none;
				padding: $md;

				span {
					position: relative;
					top: -5px;
				}

				@media screen and (max-width: $mobile) {
					min-height: 75px;
				}

				@media screen and (max-width: $tablet-landscape) {
					font-size: em(11px);
					padding: $sm;
				}

				&.disable {
					background: lighten($gray-base, 90%);
					color: $color-primary;
				}

				&.active {
					background: $action-blue;
					color: #fff;
				}

				&.completed {
					background: lighten($gray-base, 90%);
					color: $color-primary;

					i {
						color: $color-success;
					}
				}

				i {
					margin-right: $xs;
					font-size: em(20px);
					vertical-align: bottom;

					@media screen and (max-width: $tablet) {
						display: block;
					}
				}
			}
		}
	}
}

.btn-xlg {
	padding: $sm $xlg;
	border: 1px solid;
	border-radius: 4px;

	&.btn-default {
		color: $color-primary;
		border: 1px solid $input-border-color;
	}

	i {
		font-size: 2em;
	}
}

.checkbox-carga {
  text-transform: uppercase;
}

.action-steps-content {
	@include clearfix();
	margin: 50px 0;

	.btn {
		font-size: em(13px);
		padding: $md;
		text-transform: uppercase;
	}

	.btn-back {
		@extend .btn-default;
	}

	.btn-back,
	.btn-deny,
	.btn-approve {
		width: 100%;
		min-width: 195px;

		@media (min-width: 992px) {
			float: left!important;
			margin-right: 10px;
		}
		@media (min-width: $tablet) {
			width: 47%;
		}
	}

	.btn-deny {
		@extend .btn-danger;
	}

	.btn-approve {
		@extend .btn-success;
	}

	.btn-warning {
		background-color: #ffdc00;
		border-color: darken(#ffdc00, 1%);
		color: $color-primary;

		&:hover,
		&:focus {
			background-color: darken(#ffdc00, 3%);
		}
	}
}

.quotation-status {
	font-size: em(16px);
	text-align: center;
}
.content-new-cotacao{
	.controle-padding{
		div{
			padding:0;
		}
		.cnpj-dropdown:first-child + div{
			padding-right:15px;
	
		}
		.cnpj-dropdown{
			&:first-child{
			padding-right:15px;
			}
			
			&:last-child{
			padding-left:15px;
			}
		}
	}
}