// Filtros
.main-documentacao {
	.select-container {
		width: 100% !important;
	}

	.t-d-filter-cnpj,.t-d-filter-number,.t-d-filter-navio,.t-d-filter-data {
		width: 100% !important;

		@media screen and (min-width: 920px) {
			width: 20% !important;
		}
	}

	.t-d-filter-cnpj,.t-d-filter-number {
		@media screen and (max-width: 419px) {
			width: 100% !important;
		}

		@media screen and (max-width: 549px) and (min-width: 420px) {
			width: 50% !important;
		}

		@media screen and (max-width: 919px) and (min-width: 550px) {
			width: 50% !important;
		}
	}

	.t-d-filter-navio {
		@media screen and (max-width: 767px) {
			width: 100% !important;
		}
	}

	.t-d-filter-data {
		@media screen and (max-width: 555px) {
			width: 100% !important;

			.datepicker-calendar {
				left: 0;
			}
		}

		@media screen and (max-width: 919px) and (min-width: 556px) {
			width: 50% !important;

			.datepicker-calendar {
				left: 0;
			}
		}

		@media screen and (min-width: 920px) {
			.datepicker-calendar {
				left: auto;
				right: 0;
			}
		}
	}

	.customer-profile {
		.t-d-filter-cnpj {
			display: none !important;
		}

		.t-d-filter-number {
			@media screen and (max-width: 919px) {
				width: 100% !important;
			}

			@media screen and (min-width: 920px) {
				width: 25% !important;
			}
		}

		.t-d-filter-number,.t-d-filter-navio,.t-d-filter-data {
			@media screen and (min-width: 920px) {
				width: 25% !important;
			}
		}
	}
}

.main-search {
  .t-d-filter-number {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(6, $gutter: 20px);
    @include make-lg-column(2.4, $gutter: 20px);

		.form-control {
			margin-left: 2px !important;
			height: 55px !important;
		}
  }

	.t-d-filter-navio {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(6, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
  }

	.t-d-filter-status {
		@include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(4, $gutter: 20px);
    @include make-md-column(4, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
	}

	.t-d-filter-data {
		@include make-xs-column(6, $gutter: 20px);
		@include make-sm-column(4, $gutter: 20px);
		@include make-md-column(3.5, $gutter: 20px);
		@include make-lg-column(1.7, $gutter: 20px);
	}

	.search-action-documents {
		@include make-xs-column(12, $gutter: 20px);
		@include make-sm-column(12, $gutter: 20px);
		@include make-md-column(1, $gutter: 20px);
		@include make-lg-column(0.8, $gutter: 20px);
	}

	.t-d-filter-number,
	.t-d-filter-navio,
	.t-d-filter-status,
	.t-d-filter-data {
		max-width: inherit !important;
		display: inline-block !important;
		min-height: 60px !important;

		.select-group {
			min-height: 60px !important;
		}

		.datepicker-container,
		.datepicker-input-container,
		.datepicker-input {
			width: 85px !important;
		}

		@media screen and (max-width: $desktop) {
			border-bottom: 1px solid lighten($search-label-color, 35%);
		}
	}

	.t-d-filter-number {
		.form-control {
			// font-size: em(12px);
			min-height: 55px;
		}
	}

	.t-d-filter-navio,
	.t-d-filter-data {
		@media screen and (max-width: $tablet) {
			border-right: 1px solid lighten($search-label-color, 35%);
		}
	}

	.search-action-documents {
		@media screen and (max-width: $tablet-landscape) {
			margin-top: 0;
		}

		.btn-search {
			@media screen and (max-width: $tablet-landscape) {
				width: 98%;
				min-height: inherit;
				margin: $xs;
				border-radius: 4px;
			}

			@media (min-width: $tablet-landscape) and (max-width: $desktop) {
				border-top-right-radius: 0;
			}
		}

		@media (min-width: $tablet) and (max-width: $tablet-landscape) {
			position: relative;
			right: -8px;
		}

		@media (min-width: $tablet-landscape) {
			position: absolute;
			border-top-right-radius: 0;
			right: -7px;
		}
	}

}

.result-documentacao-wrapper {
	$title-color: #838591;

	.product-documentacao {
		$title-color: #838591;
		@include clearfix();
		position: relative;
		padding: 15px 0 15px $sm;
		margin-bottom: $sm;
		background-color: #fff;
		box-shadow: 0 1px 1px 0 rgba(98, 98, 98, 0.5);
		@media screen and (max-width: $tablet-landscape) {
			padding: 15px $sm 60px;
		}

		&_item {
			@media screen and (max-width: $mobile) {
				margin-bottom: $sm;
			}

			.control-label {
				font-family: $font-2;
				font-size: em(11px);
				font-weight: 600;
				text-transform: none;
				color: $title-color;
			}

			.icon {
				position: relative;
				top: 5px;
				color: lighten($title-color, 10%);
			}
		}

		&_details {
			font-family: $font-2;
			font-size: em(12px);
		}

		.local {
			@include clearfix();
			font-size: em(15px);
			color: $color-primary;
			margin: 0 0 5px;
		}

		.n-proposta {
			@include text-overflow();
			font-size: em(14px);
			font-weight: bold;
			color: $color-primary;
			margin: 0;
			@media screen and (max-width: $tablet-landscape) {
				font-size: em(12px);
			}
		}

		.deadline {
			color: $color-primary;
		}

		.container-details {
			margin-left: 35px;

			p {
				margin: 0;
			}

			.icon {
				font-size: em(16px);
				color: #59639f;
				margin-right: 3px;
			}
		}

		p {
			@include text-overflow();

			.sub-label {
				font-size: em(12px);
				color: $title-color;
			}
		}
	}

	.icon {
		position: relative;
		top: 5px;
		color: lighten($title-color, 10%);
	}

	$xs-res: 6;
	$sm-res: 3.5;
	$md-res: 1.5;
	$lg-res: 1.6;

	.check-documentacao {
		@include make-xs-column(6, $gutter: 15px);
		@include make-sm-column(5, $gutter: 15px);
		@include make-md-column(0.5, $gutter: 15px);
		@include make-lg-column(0.5, $gutter: 15px);

		text-align: center;
		@media screen and (max-width: $desktop) {
			height: 100px;
			padding-top: 29px;
		}


	}

	.navio_viagem {
		@include make-xs-column(6, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column($sm-res, $gutter: 15px);
		@include make-lg-column(2.5, $gutter: 15px);
	}

	.referencia {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.2, $gutter: 15px);
		@include make-lg-column(1.2, $gutter: 15px);
	}
	.numero-cte {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.5, $gutter: 15px);
		@include make-lg-column(1.3, $gutter: 15px);
	}

	.boleto {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.5, $gutter: 15px);
		@include make-lg-column(1.3, $gutter: 15px);
	}

	.nota_fiscal {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.3, $gutter: 15px);
		@include make-lg-column(1.2, $gutter: 15px);
	}

	.emissao {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.5, $gutter: 15px);
		@include make-lg-column(1.3, $gutter: 15px);
	}

	.vencimento {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.6, $gutter: 15px);
		@include make-lg-column(1.1, $gutter: 15px);

		&.a-vencer {
			i,
			p {
				color: $color-success;
			}
		}

		&.vencido {
			i,
			p {
				color: $color-danger;
			}
		}
	}
	.compensacaoCo2{
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(2.65, $gutter: 15px);
		@include make-lg-column(1.55, $gutter: 15px);
		img{
			height: 18px;
		}
		a{
			color: #49a010;
		}
		strong{
			font-size: 14px;
			color: #49a010;
		}
		.numero{
			font-size: 11px;
		}
	}

	.solicitar-boleto {
		@include make-xs-column(12, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column($md-res, $gutter: 15px);
		@include make-lg-column($lg-res, $gutter: 15px);
		text-align: center;

		.btn-boleto {
			border-radius: $lg;
			font-weight: 300;
			font-size: 0.7em;
			padding: 0 $sm;
			margin-top: $xs;
		}
		@media screen and (max-width: $mobile) {
			padding-bottom: $sm;
		}
	}

	.actions {
		@include make-xs-column($xs-res, $gutter: 15px);
		@include make-sm-column($sm-res, $gutter: 15px);
		@include make-md-column(1.8, $gutter: 15px);
		@include make-lg-column(1.5, $gutter: 15px);
		position: absolute;
		top: 0;
		right: 0;
		background-color: #eaeff6;
		text-align: center;
		@media screen and (min-width: $desktop){
			height: 80px;
		}
		@media screen and (min-width: $tablet-landscape) and(max-width: $desktop) {
			height: 130px;

		}
		@media screen and (max-width: $tablet-landscape) {
			width: 100%;
			top: inherit;
			bottom: 0;

		}
		.btn-co2{
			img{
				display: block;
				height: 15px;
				width: auto;
				margin: 0 auto;
			}
			.caret{
				color: #49a010;
			}


		}
		.btn {
			padding: 19px 4px;
			color: $color-primary;
			background-color: transparent;
			@media screen and (max-width: $tablet-landscape) {
				padding: $sm;
			}
			@media screen and (min-width: $tablet-landscape) and(max-width: $desktop) {
				padding:49px 4px !important;
			}
			&:hover {
				color: darken($color-primary, 10%);
			}

			.icon {
				color: $color-primary;
			}
		}

		.dropdown-menu {
			border: 1px solid $input-border-color;
			padding: 0;

			.caret {
				color: $color-primary !important;
			}

			li {
				border-bottom: 1px solid $input-border-color;

				&:last-child {
					background-color: $input-border-color;
				};
			}

			a {
				display: block;
				padding: $sm;
				font-size: 0.8em;

				img {
					margin-right: $sm;
				}
			}
		}
	}

	.control-label {
		font-family: $font-2;
		font-size: em(11px);
		font-weight: 600;
		text-transform: none;
		color: $title-color;
	}
}

.nav-doc-actions {
	@include clearfix();
	font-family: $font-2;
	margin-top: -15px;
	margin-bottom: $md;

	.btn {
		font-size: em(12px);
		min-width: 80px;
	}

	.dropdown-menu {
		border: 1px solid $input-border-color;
		padding: 0;

		.caret {
			color: $color-primary !important;
		}

		li {
			border-bottom: 1px solid $input-border-color;

			&:last-child {
				background-color: $input-border-color;
			};
		}

		a {
			display: block;
			padding: $sm;
			font-size: 0.8em;

			img {
				margin-right: $sm;
			}
		}
	}
}
