// TOOLTIP

.tooltip {
  font-size: 0.8em;

  .tooltip-inner {
    min-width: 80px;
    font-family: $font-1;
    letter-spacing: .6;
    padding: $xs $sm;
    z-index: 999999999999;
  }

  @media screen and (max-width: $tablet-landscape) {
    display: none;
  }
}

.tooltip-mobile {
  display: none;

  @media screen and (max-width: $tablet-landscape) {
    display: inline-block;
  }
}

.tooltip-mobile,
.tooltip-mobile-2 {
  margin-left: $xs;
  font-family: $font-1;
  font-size: em(10px);
}

@media screen and (max-width: $tablet-landscape) {
  .tooltip {
      display: none !important;
  }
}
