.detalhes-rastreamento-nf {
  font-family: $font-2;
  h4 {
    font-size: em(12px);
    font-weight: 600;
    text-transform: uppercase;
    color: $filter-title-color;
    border-bottom: 1px solid #e0e5ef;
    padding-top: $md;
    padding-bottom: $sm;
  }
  .timeline {
    @include clearfix();
    padding-bottom: $lg;
    text-align: center;
    li {
      width: 20%;
      display: inline-block;
      text-align: center;
      position: relative;
      color: #91a3b1;
      @media screen and (max-width: $tablet-landscape) {
        text-align: left;
      }
      .active {
        .circle {
          background-color: $color-success !important;
        }
        .title {
          color: $color-success;
        }
        p {
          color: #383e40;
        }
      }
      .line {
        height: 2px;
        background: #90a4b2;
        position: absolute;
        width: 100%;
        top: 28px;
        @media screen and (max-width: $tablet-landscape) {
          width: 2px;
          height: 100%;
        }
      }
      .circle {
        width: 12px;
        height: 12px;
        background-color: #91a3b1;
        position: absolute;
        left: 50%;
        margin-left: -$sm;
        border-radius: $md;
        top: 22px;
        z-index: 100;
        @media screen and (max-width: $tablet-landscape) {
          left: 3px;
        }
      }
      &:first-child {
        .line {
          width: 50%;
          left: 50%;
          @media screen and (max-width: $tablet-landscape) {
            width: 2px !important;
            left: 0;
            height: 100%;
          }
        }
      }
      &:last-child {
        .line {
          width: 50%;
          right: 50%;
          @media screen and (max-width: $tablet-landscape) {
            display: none;
          }
        }
      }
      .title {
        font-size: em(10px);
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 50px;
        @media screen and (max-width: $tablet-landscape) {
          margin-top: $md;
        }
      }
      .local {
        font-size: em(11px);
        color: $color-primary;
        font-weight: 500;
      }
      p {
        font-size: em(11px);
        margin-bottom: 0;
      }
      .title,
      .local,
      p {
        @media screen and (max-width: $tablet-landscape) {
          margin-left: $md;
        }
      }
    }
  }
}

.result-cargas-wrapper {
  .control-label {
    font-family: $font-2;
    font-size: em(10px);
  }
  .control-info {
    font-family: $font-2;
    font-size: em(12px);
    word-wrap: break-word;
  }
  .btn-link {
    text-decoration: none;
    i {
      top: 1px;
      color: $color-primary;
    }
  }
}
