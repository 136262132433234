$input-bg-color: #fff;
$input-border-color: #cbd5e7;
$input-text-color: #133157;
$control-label-color: #909799;

.control-label {
  font-size: em(12px);
  color: $control-label-color;
  font-weight: 500;
  text-transform: uppercase;
}

.control-label-admin {
  font-size: em(13px);
  // color: $input-text-color;
  font-weight: lighter;
}

.control-label-perfil {
  font-size: em(12px);
  text-transform: inherit !important;
  color: $input-text-color;
  font-weight: lighter;
  position: relative;
  top: -8px;
}

.form-control {
  height: 45px;
  border: 1px solid $input-border-color;
  color: $input-text-color;
  font-size: em(13px);
  font-family: $font-2;
  background-color: $input-bg-color;
  box-shadow: 0px 1px 1px 0px rgba(204, 215, 230, 0.5);
  border-radius: 4px;
  transition: border-color .2s ease;
  -webkit-transition: border-color .2s ease;
  outline: 0;
}

.input-icon {
  @include clearfix();
  position: relative;

  .icon {
    font-size: em(18px);
    position: absolute;
    top: 15px;
    right: 20px;
    color: $control-label-color;
  }

  .form-control {
    width: 100%;
    height: 50px;
  }
}

.bootstrap-datetimepicker-widget {
  font-family: $font-2;
  font-size: em(12px);
}

.label {
  display: inline-block;
  padding: $xs;
}

.label-xs {
  font-size: em(10px);
  padding: 3px $xs;
  min-width: 20px;
}

.label-block{
   min-width: 75px;
}

.alert {
  font-family: $font-2;
  font-size: em(13px);
}

.alert-xs {
  padding: $sm;
  font-size: em(13px);
}

.alert-danger {
  color: $color-danger;
  margin-bottom: $md;
  // padding: $sm 15px;
  // background-color: transparent;
  // border-color: transparent;
}

.has-error .form-control {
  border-color: $color-danger;
}

.input-sm {
  height: 35px !important;
}

.form-horizontal {
  .control-label {

  }
}

.radio-btn-custom {

  @media screen and (max-width: $tablet-landscape) {
    text-align: center;
  }

  input[type="radio"] {
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    color: lighten($gray-base, 60%);
  }

  input[type="radio"] + label span {
    width: 100%;
    display:inline-block;
    padding: $md $lg $sm;
    margin:-1px 4px 5px 0px;
    vertical-align:middle;
    cursor:pointer;
    -moz-border-radius:  50%;
    border-radius:  10px;
    border: 1px solid $input-border-color;
    font-weight: 400;

    i {
      display:block;
      font-size: 2.5em;
      text-align: center;
      height: 24px
    }

  }

  input[type="radio"] + label span {
      color:$color-primary;
  }

  input[type="radio"]:checked + label span{
       background-color:$color-primary;
       color:#fff;

  }

  input[type="radio"] + label span,
  input[type="radio"]:checked + label span {
    -webkit-transition:background-color 0.2s linear;
    -o-transition:background-color 0.2s linear;
    -moz-transition:background-color 0.42s linear;
    transition:background-color 0.2s linear;
  }
input[type="radio"][disabled]+ label span {
    cursor: not-allowed !important;
    color: #bbc2cc;
    border-color: darken(#eeeeee, 2%);
    background: #eeeeee !important;
  }
  input[type="radio"]:focus + label span{
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
}

.form-group {
  .alert {
    margin-top: $xs;
    padding: $sm;
  }
}

.form-group-assuntos {
  position: relative;

  .form-control-loading {
    top: 40%;
    right: 3%;
  }
}


// Checkbox Service Item

.check-service-item {
  @include clearfix();
  background-color: #fbfbfb;
  font-size: em(12px);
  margin-bottom: $sm;

  // @media screen and (max-width: $tablet-landscape) {
  //   padding: $sm $sm 0;
  // }

  input[type="checkbox"] {
    display:none;
  }

  input[type="checkbox"] + label {
    color: lighten($gray-base, 60%);
    width: 100%;
    margin-bottom: 0;
  }

  input[type="checkbox"] + label span {
    width: 100%;
    display: inline-block;
    padding: $sm 20px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid $input-border-color;
    font-weight: 400;
    line-height: normal;

    .icon {
      height: 32px;
      float: left;
      margin-right: $sm;
      font-size: em(36px);
      color: #0773c0;
      text-align: center;

      @media screen and (max-width: $tablet-landscape) {
        font-size: em(30px);
      }
    }
  }

  input[type="checkbox"] + label span {
    color: #133157;
  }

  input[type="checkbox"]:checked + label span{
   background-color: lighten(#133157, 10%);
   color:#fff;

   .icon {
     color: #fff;
   }
  }

  input[type="checkbox"] + label span,
  input[type="checkbox"]:checked + label span {
    -webkit-transition:background-color 0.2s linear;
    -o-transition:background-color 0.2s linear;
    -moz-transition:background-color 0.42s linear;
    transition:background-color 0.2s linear;
  }
}


// Special Radio - Booking
.radio-service-item {
  @include clearfix();
  width: 100%;
  margin-bottom: $md;
  display: inline-block;
  padding: $sm 15px 15px;
  vertical-align: middle;
  border-radius: 0;
  border: 0;
  background: #f5f6f8;
  box-shadow: 0 2px 4px 0 rgba(193,195,199,0.3);
  transition: all 0.3s ease;

  .divisor{
    border-left: 1px solid #ccd5e6;
  }

  .title {
    font-family: $font-2;
    font-size: em(12px);
    font-weight: 400;
    line-height: normal;
    margin-bottom: $xs;
    display: block;
  }

  .icon {
    height: 36px;
    float: left;
    margin-right: $sm;
    font-size: em(24px);
    color: #0773c0;
    text-align: center;
  }

  .radio-group {
    display: inline-flex;
    border-radius: 12px;
    height: 20px;
    min-width: 90px;
    background: #f6f6f6;
    border: solid 1px #e7eaeb;
    position: relative;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: rgba(216,233,251,0.3);
      border: solid 1px #8bc8f6;
    }

    input {
      display: none !important;
    }

    .radio,
    .radio-group-ui {
      width: 14px;
      height: 14px;
      padding: 5px 14px;
      margin: 2px;
      border-radius: 50%;
      cursor: pointer;
    }

    .radio {
      position: relative;

      &:not(:last-child) {
        margin-right: 0;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #f6f6f6;
        box-shadow: inset 0 0 3px #aaa;
      }
    }

    input[type="radio"]:checked + .radio::after {
      @include transition( .2s cubic-bezier( 1, -1, 0.4, 1.4 ) all );
      position: absolute;
      z-index: 2;
      width: 14px;
      height: 14px;
    }

    input[type="radio"]:checked + .radio-yes::after {
      background-color: $color-success;
      box-shadow: none;
    }

    input[type="radio"]:checked + .radio-no::after {
      background-color: $color-danger;
      box-shadow: none;
    }

    input[type="radio"]:checked + .radio-empty::after {
      background-color: #fff;
      box-shadow: 0 0 3px #aaa;
    }
  }

  .radio-content {
    font-family: $font-2;
    font-size: em(11px);
    font-weight: 400;
    color: #000;
    position: relative;
    top: -7px;
    left: 2px;
  }
}

.services-hint {
  @include clearfix();
  margin: -10px 0 30px;
  list-style: none;
  list-style-type: none;
  text-align: center;

  li {
    font-family: $font-2;
    font-size: em(11px);
    font-weight: 400;
    padding-right: 10px;
    display: inline;
  }

  .bullet {
    &:before {
      content: '';
      display: inline-block;
      margin-right: $xs;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    &.yes:before {
      background-color: $color-success;
    }

    &.no:before {
      background-color: $color-danger;
    }

    &.empty:before {
      background-color: #fff;
      box-shadow: 0 0 3px #aaa;
    }
  }
}

#modalFinalizarNota .form-control-loading {
  width: 24px;
  height: 24px;
  margin: 5px 5px 0 0;
  position: absolute;
  top: 16px;
  right: 12px;
}
