.collapse-transbordo{
	float: left;
	width: 100%;
	font-size: 0.85em;
	margin-top: $md;
	.item-transbordo{
		float: left;
		width: 25%;
		padding:0 $sm;
		h5{
			color: $action-blue;
		}
	}
}