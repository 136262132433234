$input-bg-color: #fff;
$input-border-color: #cbd5e7;
$input-text-color: #133157;
$control-label-color: #7a7f82;
.btn-filters-mobile {
  @media screen and (max-width: $tablet) {
    margin-top:$sm;
  }
}
.export {
  color: $action-blue;
  height: 30px;
  line-height: 30px;
  display: block;
  vertical-align: middle;
  font-size: 11px;
  display: inline-block;
  @media screen and (min-width: $tablet) {
  float: right;
  }
  .icon {
    color: $color-success;
    font-size: 18px;
    float: left;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}
.filter-action {
  @media screen and (max-width: 767px) {
    float: none!important;
    margin-top: 70px;
  }
  @media screen and (min-width: $tablet) {
    float: right!important;
    margin-top: 5px;
  }
  ul {
    @media screen and (min-width: $tablet) {
      float: right;
    }
    li {
      float: left;
      font-size: 11px;
      @include vertical-align();
      margin: 0;
      min-height: 30px;
      @media screen and (min-width: $tablet) {
        margin: $sm 0 $sm $sm;
      }
      @media screen and (max-width: 767px) {
        // width: 100%;
        float: none;
        min-height: 46px;
      }
      a {
        &.export {
          color: $action-blue;
          height: 40px;
          line-height: 40px;
          display: block;
          vertical-align: middle;
          .icon {
            color: $color-success;
            font-size: 18px;
            float: left;
          }
        }
      }
      .select-group {
        height: 45px;
        border: 1px solid $input-border-color;
        color: $input-text-color;
        font-size: em(13px);
        font-family: $font-2;
        background-color: $input-bg-color;
        box-shadow: 0px 1px 1px 0px rgba(204, 215, 230, 0.5);
        border-radius: 8px;
        transition: border-color .2s ease;
        outline: 0;
      }
    }
  }
}

.result-list-wrapper {
  width: 100%;
}
