.login-modal {

  .modal-dialog {
    width: 420px;

    @media screen and (max-width: $tablet) {
      width: 95%;
      margin: 20px auto;
    }
  }

  .modal-content {
    background-color: $body-background;
    box-shadow: 0 1px 2px rgba(0,0,0,.1), 0 -1px 0 rgba(0,0,0,.02);
    border-radius: 3px;
    outline: 0;
  }

  .modal-body {
    position: relative;
    padding: $lg $md;
  }

  .modal-footer {
    padding: 0 $md $md;
    text-align: left;
    border-top: 0;
  }

  .control-label {
    font-size: em(11px);
  }

  .btn {
    padding: 15px;
    border-radius: 8px;
    transition: all .2s;
  }

  .btn-link {
    font-family: $font-2;
    padding: $sm;
    color: #95a5a6;
  }

  .close {
    font-family: $font-2;
    position: absolute;
    top: 15px;
    right: 5px;
    padding: $sm 15px;
  }
}
