$laranja: #ff7e00;
$verde: #24b543;
$azul: #002b5c;
$vermelho: #b53030;
$laranja-medio: #c96a12;
$verde-medio: #117725;
$azul-medio: #072647;
$vermelho-medio: #a72727;
$laranja-forte: #a5540d;
$verde-forte: #0d5a1b;
$azul-forte: #041c35;
$vermelho-forte: #8d1515;
// Panel Provedor - Filtros
.main-search.vincular-nfs {
  .controle-inputs{
    width: 100%;
    @media screen and (min-width: 992px)  {
    width: calc(100% - 60px);
    }
    float: left;
  }
  .customer-profile{
    .t-pp-filter-cnpj {
      display: none !important;
    }

    .t-pp-filter-number {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2.5, $gutter: 20px);
      @include make-lg-column(2.5, $gutter: 20px);
    }
    .t-pp-filter-navio {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2.3, $gutter: 20px);
      @include make-lg-column(2.3, $gutter: 20px);
    }
    .t-pp-filter-os {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2.3, $gutter: 20px);
      @include make-lg-column(2.3, $gutter: 20px);
    }
    .t-pp-filter-porto {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2.3, $gutter: 20px);
      @include make-lg-column(2.3, $gutter: 20px);
    }
    &.has-period {
     
      .t-pp-filter-number {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(2, $gutter: 20px);
        @include make-lg-column(2.2, $gutter: 20px);
      }
      .t-pp-filter-navio {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.8, $gutter: 20px);
        @include make-lg-column(1.8, $gutter: 20px);
      }
      .t-pp-filter-os {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.8, $gutter: 20px);
        @include make-lg-column(2, $gutter: 20px);
      }
      .t-pp-filter-porto {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.6, $gutter: 20px);
        @include make-lg-column(1.6, $gutter: 20px);
      }
      .t-pp-filter-periodo {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(12, $gutter: 20px);
        @include make-md-column(1.4, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
        border-right: 1px solid #e4e4e4 !important;
      }
      .t-pp-filter-date {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.4, $gutter: 20px);
        @include make-lg-column(1.4, $gutter: 20px);
        display: inline-block !important;
      }
    }
  }
  .has-period {
    .t-pp-filter-cnpj {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.5, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }
    .t-pp-filter-number {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.5, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }
    .t-pp-filter-navio {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.5, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }
    .t-pp-filter-os {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.4, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
    }
    .t-pp-filter-porto {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.4, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
    }
    .t-pp-filter-periodo {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.4, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
      border-right: 1px solid #e4e4e4 !important;
    }
    .t-pp-filter-date {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.4, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
      display: inline-block !important;
    }
    .search-action-tracking{
      @media (max-width: 991px) {
        margin-top: 10px;
        width: 100%;
        display: block;
        float: left;
        padding: 0 10px 10px;
      }
      .btn-search {
        
        @media (min-width: 992px)  {
          height: 60px;
         
        }
        @media (max-width: 991px) {
          width: 100%;
          margin: 0 !important;
          border-radius: 4px;
          height: 40px;
        min-height: 40px;
        }
      }
    }
  }
  .t-pp-filter-cnpj {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(1.9, $gutter: 20px);
    @include make-lg-column(1.9, $gutter: 20px);
  }
  .t-pp-filter-number {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(2, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
    .form-control {
      margin-left: 1px !important;
      height: 55px;
    }
  }
  .t-pp-filter-navio {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(1.6, $gutter: 20px);
    @include make-lg-column(1.6, $gutter: 20px);
  }
  .t-pp-filter-os {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(2, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
  }
  .t-pp-filter-porto {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(2, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
  }
  .t-pp-filter-periodo {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(1.7, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
    border-right: 0;
  }
  
  .search-action-os {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(12, $gutter: 20px);
    @include make-md-column(1, $gutter: 20px);
    @include make-lg-column(1, $gutter: 20px);
  }
  .search-action-tracking{
    @media (max-width: 991px) {
      margin-top: 10px;
      width: 100%;
      display: block;
      float: left;
      padding: 0 10px 10px;
    }
    .btn-search {
      
      @media (min-width: 992px) and (max-width: 1199px) {
        height: 60px;
       
      }
      @media (max-width: 991px) {
        width: 100%;
        margin: 0 !important;
        border-radius: 4px;
        height: 40px;
      min-height: 40px;
      }
    }
  }
  /*
  .search-action-os {
    @media screen and (max-width: $tablet-landscape) {
      margin-top: 0;
    }
    .btn-search {
      @media screen and (max-width: $tablet-landscape) {
        width: 98%;
        min-height: inherit;
        margin: $xs;
        border-radius: 4px;
      }
    }
    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      position: relative; // right: -8px;
    }
    @media (min-width: $tablet-landscape) {
      position: absolute;
    }
  }
  */

  .select-group select.form-control {
    margin-left: -4px !important;
  }
  .t-pp-filter-date {
    max-width: inherit !important;
    display: none;
  }
  .t-pp-filter-number,
  .t-pp-filter-navio,
  .t-pp-filter-os,
  .t-pp-filter-servico,
  .t-pp-filter-periodo,
  .t-pp-filter-porto {
    max-width: inherit !important;
    display: inline-block !important;
    min-height: 60px !important;
    .select-group {
      min-height: 60px !important;
    }
    @media screen and (max-width: $desktop) {
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
  }
  .t-pp-filter-cnpj {
    max-width: inherit !important;
    display: inline-block !important;
    min-height: 60px !important;
    .select-group {
      min-height: 60px !important;
    }
    @media screen and (max-width: 991px) {
      border-bottom: 1px solid lighten($search-label-color, 35%) !important;
      border-right: none !important;
    }
  }
  .t-pp-filter-number,
  .t-pp-filter-navio {
    padding-top: 0;
    .form-control {
      height: 55px;
      margin-left: 0 !important;
    }
  }
  .control-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .select-container {
    width: 100%;
  }
  
}

.main-search.minhas-os {
  .controle-inputs{
    width: 100%;
    @media screen and (min-width: 992px)  {
    width: calc(100% - 60px);
    }
    float: left;
  }
  .search-action-tracking{
    @media (max-width: 991px) {
      margin-top: 10px;
      width: 100%;
      display: block;
      float: left;
      padding: 0 10px 10px;
    }
    .btn-search {
      
      @media (min-width: 992px) and (max-width: 1199px) {
        height: 120px;
       
      }
      @media (max-width: 991px) {
        width: 100%;
        margin: 0 !important;
        border-radius: 4px;
        height: 40px;
      min-height: 40px;
      }
    }
  }

  .customer-profile{
    .t-pp-filter-cnpj {
      display: none !important;
    }

    .t-pp-filter-number {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2.1, $gutter: 20px);
      @include make-lg-column(2.1, $gutter: 20px);
    }
    .t-pp-filter-navio {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2, $gutter: 20px);
      @include make-lg-column(2, $gutter: 20px);
    }
    .t-pp-filter-os {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(2, $gutter: 20px);
      @include make-lg-column(2, $gutter: 20px);
    }
    .t-pp-filter-porto {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.8, $gutter: 20px);
      @include make-lg-column(1.8, $gutter: 20px);
    }
    .t-pp-filter-periodo {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.5, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
      border-right: 1px solid #e4e4e4 !important;
    }
    &.has-period {
     
      .t-pp-filter-number {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.5, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
      }
      .t-pp-filter-navio {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.5, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
      }
      .t-pp-filter-os {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.7, $gutter: 20px);
        @include make-lg-column(1.7, $gutter: 20px);
      }
      .t-pp-filter-porto {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.5, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
      }
      .t-pp-filter-periodo {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.3, $gutter: 20px);
        @include make-lg-column(1.3, $gutter: 20px);
        border-right: 1px solid #e4e4e4 !important;
      }
      .t-pp-filter-date {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(1.3, $gutter: 20px);
        @include make-lg-column(1.3, $gutter: 20px);
        display: inline-block !important;
      }
    }
  }
  .has-period {
    .t-pp-filter-cnpj {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.4, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
    }
    .t-pp-filter-number {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.4, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
    }
    .t-pp-filter-navio {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.3, $gutter: 20px);
      @include make-lg-column(1.3, $gutter: 20px);
    }
    .t-pp-filter-os {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.2, $gutter: 20px);
      @include make-lg-column(1.2, $gutter: 20px);
    }
    .t-pp-filter-porto {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.2, $gutter: 20px);
      @include make-lg-column(1.2, $gutter: 20px);
    }
    .t-pp-filter-servico {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.3, $gutter: 20px);
      @include make-lg-column(1.3, $gutter: 20px);
    }
    .t-pp-filter-periodo {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(12, $gutter: 20px);
      @include make-md-column(1.2, $gutter: 20px);
      @include make-lg-column(1.2, $gutter: 20px);
      border-right: 1px solid #e4e4e4 !important;
    }
    .t-pp-filter-date {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.1, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
      display: inline-block !important;
    }
    .btn-search{
      height: 60px;
    }
  }
  .t-pp-filter-cnpj {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(4, $gutter: 20px);
    @include make-lg-column(1.7, $gutter: 20px);
  }
  .t-pp-filter-number {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(4, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
    .form-control {
      margin-left: 1px !important;
      height: 55px;
    }
  }
  .t-pp-filter-navio {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(4, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
  }
  .t-pp-filter-os {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
  }
  .t-pp-filter-servico {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(2, $gutter: 20px);
  }
  .t-pp-filter-porto {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(6, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
  }
  .t-pp-filter-periodo {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(12, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.7, $gutter: 20px);
    border-right: 0;
  }
  .search-action-os {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(12, $gutter: 20px);
    @include make-md-column(1, $gutter: 20px);
    @include make-lg-column(1, $gutter: 20px);
  }
  .search-action-os {
    @media screen and (max-width: $tablet-landscape) {
      margin-top: 0;
    }
    .btn-search {
      @media screen and (max-width: $tablet-landscape) {
        width: 98%;
        min-height: inherit;
        margin: $xs;
        border-radius: 4px;
      }
    }
    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      position: relative; // right: -8px;
    }
    @media (min-width: $tablet-landscape) {
      position: absolute;
    }
  }
  .select-group select.form-control {
    margin-left: -4px !important;
  }
  .t-pp-filter-date {
    max-width: inherit !important;
    display: none;
  }
  .t-pp-filter-cnpj,
  .t-pp-filter-number,
  .t-pp-filter-navio,
  .t-pp-filter-os,
  .t-pp-filter-servico,
  .t-pp-filter-periodo,
  .t-pp-filter-porto {
    max-width: inherit !important;
    display: inline-block !important;
    min-height: 60px !important;
    .select-group {
      min-height: 60px !important;
    }
    @media screen and (max-width: $desktop) {
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
  }
  .t-pp-filter-cnpj {
    max-width: inherit !important;
    display: inline-block !important;
    min-height: 60px !important;
    .select-group {
      min-height: 60px !important;
    }
    @media screen and (max-width: 991px) {
      border-bottom: 1px solid lighten($search-label-color, 35%) !important;
      border-right: none !important;
    }
  }
  .t-pp-filter-number,
  .t-pp-filter-navio {
    padding-top: 0;
    .form-control {
      height: 55px;
      margin-left: 0 !important;
    }
  }
  .control-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .select-container {
    width: 100%;
  }

  @media screen and (min-width: 768px)  {
    .datepicker-calendar{
      right: 0 !important;
    }
  }
}

#modalFinalizarNota .page-loader {
  height: 100px !important;
  min-height: auto !important;
}

.botao-cancelar {
  margin-top: 5px;
  a {
    font-size: 16px;
    margin-left: 5px;
    color: #999;
  }
}

.vincular-nfs {
  .product-cargas {
    @media (max-width: 992px) {
      padding: 15px 10px 0;
    }
  }
}

.minhas-os.steps-wrapper .steps-cargas li,
.vincular-nfs.steps-wrapper .steps-cargas li {
  width: auto !important;
  max-width: 150px !important;
  .icon-next {
    padding-left: 13px;
  }
}

.step {
  &-a-vincular a {
    color: $laranja;
  }
  &-vinculadas a {
    color: $verde;
  }
  &-ctes-emitidos a {
    color: $azul;
  }
  &-pendentes a {
    color: $vermelho;
  }
}

.agendar.step {
  &-a-vincular {
    color: $laranja;
  }
  &-vinculadas {
    color: $verde;
  }
  &-ctes-emitidos {
    color: $azul;
  }
  &-pendentes {
    color: $vermelho;
  }
}

.agendar {
  .btn-finalizar.step {
    &-a-vincular {
      color: white;
      background-color: $laranja;
    }
    &-a-vincular:hover,
    &-a-vincular:focus {
      background-color: $laranja-medio;
    }
    &-a-vincular:active {
      background-color: $laranja-forte;
    }
    &-vinculadas {
      color: white;
      background-color: $verde;
    }
    &-vinculadas:hover,
    &-vinculadas:focus {
      background-color: $verde-medio;
    }
    &-vinculadas:active {
      background-color: $verde-forte;
    }
    &-ctes-emitidos {
      color: white;
      background-color: $azul;
    }
    &-ctes-emitidos:hover,
    &-ctes-emitidos:focus {
      background-color: $azul-medio;
    }
    &-ctes-emitidos:active {
      background-color: $azul-forte;
    }
    &-pendentes {
      color: white;
      background-color: $vermelho;
    }
    &-pendentes:hover,
    &-pendentes:focus {
      background-color: $vermelho-medio;
    }
    &-pendentes:active {
      background-color: $vermelho-forte;
    }
  }
}
