// Left icon line-height variables
$os-height-check-sm: 450px;
$os-height-check-md: 410px;
$os-height-check-lg: 98px;
$os-height-sm: 420px;
$os-height-md: 380px;
$os-height-lg: 98px;
.result-ordem-servicos-wrapper {
  $title-color: #838591;
  .product-cargas {
    $title-color: #838591;
    @include clearfix();
    position: relative;
    padding: 15px 0 11px $sm;
    margin-bottom: $sm;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(98, 98, 98, 0.5);
    &.os {
      min-height: 98px;
    }
    @media screen and (max-width: $tablet-landscape) {
      padding: 15px $sm 40px;
    }
    &_item {
      @media screen and (max-width: $tablet) {
        margin-bottom: $sm;
      }
      .control-label {
        font-family: $font-2;
        font-size: em(11px);
        font-weight: 600;
        text-transform: none;
        color: $title-color;
      }
      .icon {
        position: relative;
        top: 4px;
        color: lighten($title-color, 10%);
        float: left;
        margin-right: $xs;
      }
    }
    &_details {
      font-family: $font-2;
      font-size: em(12px);
    }
    .data,
    .local {
      @include clearfix();
      font-size: em(15px);
      color: $color-primary;
      margin: 0 0 5px;
    }
    .data {
      font-weight: bold;
    }
    .n-proposta {
      @include text-overflow();
      font-size: em(13px);
      font-weight: bold;
      color: $color-primary;
      margin: 0;
      @media screen and (max-width: $tablet-landscape) {
        font-size: em(12px);
      }
    }
    .deadline {
      color: $color-primary;
    }
    .container-details {
      margin-left: 35px;
      p {
        margin: 0;
      }
      .icon {
        font-size: em(16px);
        color: #59639f;
        margin-right: 3px;
      }
    }
    p {
      /*@include text-overflow();*/
      .sub-label {
        font-size: em(12px);
        color: $title-color;
      }
    }
  }
  .icon {
    position: relative;
    top: 5px;
    color: lighten($title-color, 10%);
  }
  .icon-step {
    @include make-xs-column(1.2, $gutter: 5px);
    @include make-sm-column(.7, $gutter: 5px);
    @include make-md-column(.4, $gutter: 5px);
    @include make-lg-column(.4, $gutter: 5px);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $azul;
    text-align: center;
    vertical-align: middle;
    i {
      top: 40%;
      font-size: 1.5em;
      color: #fff;
      @media screen and (max-width: 768px) {
        top: 50%;
      }
      @media screen and (min-width: 768px) and (max-width: 992px) {
        top: 45%;
      }
      @media screen and (min-width: 992px) {
        top: 40%;
      }
    }
  }
  .booking {
    margin-left: 6% !important;
    @include make-xs-column(12, $gutter: 5px);
    @include make-sm-column(12, $gutter: 5px);
    @include make-md-column(12, $gutter: 5px);
    @include make-lg-column(12, $gutter: 5px);
    p {
      i {}
      span {
        display: inline-block;
        line-height: $sm;
        vertical-align: bottom;
        font-weight: 300;
      }
    }
    h3{
      display: inline;
    }
  }
  .check-ordem {
    @include make-xs-column(12, $gutter: 5px);
    @include make-sm-column(12, $gutter: 5px);
    @include make-md-column(0.4, $gutter: 5px);
    @include make-lg-column(0.4, $gutter: 5px);
    @media screen and (max-width: 768px) {
      margin-left: 10%;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      margin-left: 8%;
    }
    p {
      i {}
      span {
        display: inline-block;
        line-height: $sm;
        vertical-align: bottom;
        font-weight: 300;
      }
    }
  }
  $xs-res: 5;
  $sm-res: 4;
  $md-res: 1.8;
  $lg-res: 2;
  .ordem-servico,
  .fatura,
  .data-programacao,
  .agendar,
  .navio_viagem,
  .booking,
  .destinatario,
  .tipo-servico,
  .previsao_chegada,
  .embarque {
    @media screen and (max-width: $tablet) {
      margin-left: 10%;
    }
    @media screen and (min-width: $tablet) and (max-width: $tablet-landscape) {
      margin-left: 8%;
    }
  }
  .booking,
  .embarcador,
  .agendar {
    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      margin-left: 8%;
    }
  }
  .booking,
  .check-ordem {
    @media screen and (min-width: 992px) {
      margin-left: 3%;
    }
  }
  .navio_viagem {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column($sm-res, $gutter: 5px);
    @include make-md-column(1.6, $gutter: 5px);
    @include make-lg-column(1.4, $gutter: 5px);
  }
  .ordem-servico {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column($sm-res, $gutter: 5px);
    @include make-md-column(1.3, $gutter: 5px);
    @include make-lg-column(1.4, $gutter: 5px);
  }
  .tipo-servico {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column($sm-res, $gutter: 5px);
    @include make-md-column(1.5, $gutter: 5px);
    @include make-lg-column(1, $gutter: 5px);
  }
  .fatura {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column($sm-res, $gutter: 5px);
    @include make-md-column(1, $gutter: 5px);
    @include make-lg-column(1, $gutter: 5px);
  }
  .data-programacao {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column($sm-res, $gutter: 5px);
    @include make-md-column(1.5, $gutter: 5px);
    @include make-lg-column(1, $gutter: 5px);
  }
  .embarcador {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column(3.5, $gutter: 5px);
    @include make-md-column($md-res, $gutter: 5px);
    @include make-lg-column(1, $gutter: 5px);
  }
  .embarque {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column($sm-res, $gutter: 5px);
    @include make-md-column(1.8, $gutter: 5px);
    @include make-lg-column(1.4, $gutter: 5px);
  }
  .destinatario {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column(3.5, $gutter: 5px);
    @include make-md-column($md-res, $gutter: 5px);
    @include make-lg-column(1, $gutter: 5px);
  }
  .previsao_chegada {
    @include make-xs-column($xs-res, $gutter: 5px);
    @include make-sm-column(3, $gutter: 5px);
    @include make-md-column(1, $gutter: 5px);
    @include make-lg-column(1.2, $gutter: 5px);
  }
  .agendar {
    @include make-xs-column(12, $gutter: 5px);
    @include make-sm-column(12, $gutter: 5px);
    @include make-md-column(1.32, $gutter: 5px);
    @include make-lg-column(1.32, $gutter: 5px);
    text-align: center;
    @media screen and (max-width: $tablet-landscape) {
      text-align: left;
    }
    .btn-finalizar {
      border-radius: $lg;
      width: 100%;
      font-weight: 300;
      font-size: 0.7em;
      padding: 0 $sm;
      margin-top: $sm;
      @media screen and (max-width: $tablet-landscape) {
        max-width: 90%;
      }
    }
  }
  .actions {
    @include make-xs-column($xs-res, $gutter: 0px);
    @include make-sm-column($sm-res, $gutter: 0px);
    @include make-md-column(0.4, $gutter: 0px);
    @include make-lg-column(0.4, $gutter: 0px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #eaeff6;
    text-align: center;
    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
      top: inherit;
      bottom: 0;
    }
    @media screen and (max-width: $tablet) {
      bottom: -10px;
    }
    .btn {
      padding: 40px 12px 37px;
      color: $color-primary;
      background-color: transparent;
      @media screen and (max-width: $tablet-landscape) {
        padding: $sm;
        width: 100%;
      }
      &:hover {
        color: darken($color-primary, 10%);
      }
    }
  }
  .control-label {
    font-family: $font-2;
    font-size: em(11px);
    font-weight: 600;
    text-transform: none;
    color: $title-color;
  }
}

.product-cargas-collapse {
  @include clearfix();
  position: relative;
  margin-top: -10px;
  margin-bottom: $sm;
  background-color: #f7f7f7;
  box-shadow: 0 1px 1px 0 rgba(98, 98, 98, .5);
  @media screen and (max-width: $tablet-landscape) {
    margin-top: -5px;
  }
  .panel-body {
    padding: $md $md $sm;
    font-family: $font-2;
  }
}

.ordem-servico {
  &.check-ordem{
    @media screen and (max-width: 767px) {
      margin-left: 10%;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-left: 8%;
    }
    @media screen and (min-width: 992px) {
      margin-left: 6%;
    }
  }
  .tipo-os {
    background-color: #9db1c9;
    border: 0;
    margin-top: 3px;
    padding: 2px $sm;
    border-radius: 4px;
    color: #fff;
    font-weight: normal;
    font-size: em(10px);
  }
}
