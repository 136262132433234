$filter-title-color: #949aa6;

.content-filters-wrapper {
  @include make-xs-column(12, $gutter: 20px);
  @include make-sm-column(12, $gutter: 0);
  @include make-md-column(2.2, $gutter: 0);
  @include make-lg-column(2.3, $gutter: 0);

  @media (min-width: 992px) {
    width: 21%;
  }
  @media (min-width: 1200px) {
    width: 17%;
  }

  @media screen and (max-width: $tablet-landscape) {
    padding: 0 $md;
  }
}

// .content-filters-js {
//   @media screen and (max-width: $tablet-landscape) {
//     display: none;
//   }
// }

.filter-group {
  @include clearfix();
  margin-bottom: 35px;

  @media screen and (max-width: $tablet-landscape) {
    margin-bottom: $md;
  }

  &_name {
    font-family: $font-2;
    font-size: em(13px);
    font-weight: 600;
    color: $filter-title-color;
    margin: 0;

    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
    }
  }

  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    padding-top: 1px !important;
  }

  &_item {
    position: relative;
    font-family: $font-2;
    margin: 15px 0 0;
    font-weight: 500;

    @media screen and (max-width: $tablet-landscape) {
      float: left;
      min-width: 30%;
      font-size: em(13px);
      margin: $sm $md $sm 0;
    }
  }

  .icon {
    font-size: em(18px);
    color: $filter-title-color;
    margin: 0 15px 0 $sm;
    position: relative;
    top: 2px;

    @media screen and (max-width: $tablet-landscape) {
      margin: 0 $xs 0 $xs;
    }
  }

  .icon-group {
    .icon:first-of-type {
      margin-left: $sm;
    }

    .icon:last-of-type {
      margin-right: $sm;
    }

    .icon {
      margin: 0 2px;
    }
  }

  .custom-checkbox [type=checkbox]:checked+label:after,
  .custom-checkbox [type=checkbox]:checked+label:before,
  .custom-checkbox [type=checkbox]:not(:checked)+label:after,
  .custom-checkbox [type=checkbox]:not(:checked)+label:before,
  .custom-radio [type=checkbox]:checked+label:after,
  .custom-radio [type=checkbox]:checked+label:before,
  .custom-radio [type=checkbox]:not(:checked)+label:after,
  .custom-radio [type=checkbox]:not(:checked)+label:before,
  .report-checkbox [type=checkbox]:checked+label:after,
  .report-checkbox [type=checkbox]:checked+label:before,
  .report-checkbox [type=checkbox]:not(:checked)+label:after,
  .report-checkbox [type=checkbox]:not(:checked)+label:before{
    top: -1px !important;
  }

  .custom-checkbox [type=checkbox]:checked+label,
  .custom-checkbox [type=checkbox]:not(:checked)+label,
  .custom-radio [type=checkbox]:checked+label,
  .custom-radio [type=checkbox]:not(:checked)+label,
  .report-checkbox [type=checkbox]:checked+label,
  .report-checkbox [type=checkbox]:not(:checked)+label {
    @media screen and (max-width: $tablet-landscape) {
      padding-left: 22px;
    }
  }
}

.btn-filters-mobile {
  @extend .btn;
  @extend .btn-default;
  font-size: em(11px);
  padding: $xs $lg;
  border-radius: 12px;
}

.content-filters-js.in,
.content-filters-js.collapsing {
  display: block!important;
}

@media (min-width: 992px) {
  .content-filters-js.collapse {
    display: block!important;
  }
}
