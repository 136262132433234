.tp-q {
  &_proposta {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(1.8, $gutter: 15px);
    @include make-lg-column(1.8, $gutter: 15px);
  }

  &_container {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(2, $gutter: 15px);
    @include make-lg-column(2, $gutter: 15px);
  }

  &_embarque {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(2.3, $gutter: 15px);
    @include make-lg-column(2.3, $gutter: 15px);
  }

  &_desembarque {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(2.3, $gutter: 15px);
    @include make-lg-column(2.3, $gutter: 15px);
  }

  &_navio_viagem {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(2.1, $gutter: 15px);
    @include make-lg-column(2.1, $gutter: 15px);
  }

  &_transit_time {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(1.5, $gutter: 15px);
    @include make-lg-column(1.5, $gutter: 15px);
  }

  &_deadline {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(1.5, $gutter: 15px);
    @include make-lg-column(1.5, $gutter: 15px);
  }

  &_booking {
    @include make-xs-column(12, $gutter: 15px);
    @include make-sm-column(12, $gutter: 15px);
    @include make-md-column(2.1, $gutter: 15px);
    @include make-lg-column(2, $gutter: 15px);
  }

  &_actions {
    @include make-xs-column(2, $gutter: 15px);
    @include make-sm-column(2, $gutter: 15px);
    @include make-md-column(1.2, $gutter: 0);
    @include make-lg-column(1.2, $gutter: 0);
  }

  &_booking {
    text-align: center;

    .btn-booking {
      min-width: 90px;
      min-height: inherit;
      border-radius: 20px;
      font-size: em(11px);
      transition: box-shadow .2s ease,border .2s ease;
      padding: 6px $sm;
      margin-top: 15px;

      &:hover {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .25);
      }
    }

    .btn-status {
      min-height: inherit;
      border-radius: 20px;
      font-size: em(10px);
      transition: box-shadow .2s ease,border .2s ease;
      padding: 4px $sm;
      margin-top: 0;

      &:hover {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .25);
      }
    }
  }

  &_actions {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #eaeff6;
    text-align: center;

    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
      top: inherit;
      bottom: -16px;
    }

    .btn {
      padding: 37px 12px;
      color: $color-primary;
      background-color: transparent;

      @media screen and (max-width: $tablet-landscape) {
        padding: $sm;
      }

      @media (min-width: $tablet-landscape) and (max-width: $desktop) {
        padding: 41px 8px;
      }

      &:hover {
        color: darken($color-primary, 10%);
      }
    }
  }
}

.main-search {
  .t-q-filter-cnpj {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(12, $gutter: 20px);
    @include make-md-column(12, $gutter: 20px);
    @include make-lg-column(12, $gutter: 20px);

    @media screen and (max-width: $tablet-landscape) {
      border-right: 0;
      border-bottom: 1px solid #e4e4e4;
    }

    .select-group {
      max-width: inherit !important;
      display: inline-block !important;
      min-height: 60px !important;
    }

    .form-control {
      margin-left: 1px !important;
    }
  }
}

.customer-profile {
  .t-q-filter-cnpj {
    display: none !important;
  }
}

.quotation-status {
  font-family: $font-1;
  font-size: em(11px);
  text-align: center;
  margin: $xs 0;

  &.active {
    color: $color-success;
    // margin-top: 25px;
  }

  &.expired {
    color: $color-danger;
    margin-top: 25px;
  }

  &.waiting {
    color: lighten($color-primary, 40%);
    text-overflow: inherit;
    white-space: normal;
    margin-top: 15px;
  }

  @media screen and (max-width: $tablet-landscape) {
    display: block;
  }
}
