.conteiner {
  background-size: cover;
  display: inline-block;
  position: relative;
  float: left;

  background-image: url(#{$img-path}/containers/standard_not_found.gif);

  &-xs {
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
  }

  &-lg {
    width: 52px;
    height: 52px;
    position: relative;
    top: 0;
  }
}

.flat_rack_20 {
  background-image: url(#{$img-path}/containers/flat_rack_20.gif);
}

.flat_rack_40 {
  background-image: url(#{$img-path}/containers/flat_rack_40.gif);
}

.open_top_20 {
  background-image: url(#{$img-path}/containers/open_top_20.gif);
}

.open_top_40 {
  background-image: url(#{$img-path}/containers/open_top_40.gif);
}

.reefer_20 {
  background-image: url(#{$img-path}/containers/reefer_20.gif);
}

.reefer_40 {
  background-image: url(#{$img-path}/containers/reefer_40.gif);
}

.standard_20 {
  background-image: url(#{$img-path}/containers/standard_20.gif);
}

.standard_40 {
  background-image: url(#{$img-path}/containers/standard_40.gif);
}

.conteiner-group {

  .conteiner {
    float: left;
  }

  .conteiner-type {
    float: left;
    margin-top: 15px;
    margin-left: $sm;
  }
}
