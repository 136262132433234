/*------------------------------------------------------------*\
    MAIN
\*------------------------------------------------------------*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
}

html {
  overflow-y: hidden;
  height: 100%;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

* html #all {
  height: 100%;
  min-height: 100%;
}

#all {
  margin: 0 auto;
  min-height: 100%;
  position: relative;
}

body {
  background-color: darken($body-background, 1%) !important;
  backface-visibility: hidden;
  font-family: $font-1;
  -webkit-font-smoothing: antialiased;
  color: $text-color;
  position: relative;
  min-height: 100%; // padding-bottom: 6rem;
}

body.swal2-shown {
  overflow-y: auto!important;
}

body.swal2-shown {
  overflow-y: auto!important;
}

ul {
  list-style: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
  color: #b5bcca;
  font-family: $font-1;
  font-weight: 300;
}

.main-wrapper {
  min-height: 100%;
  position: relative;
  margin: 0 auto -75px;
  @media screen and (max-width: $tablet-landscape) {
    margin-top: 50px;
  }
}
.peril-admin{
 
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .side-collapse-container {
        width: 100%;
        position: relative;
        left: 0;
        transition: left .4s;
        -webkit-transition: left .4s;
      }
      .side-collapse {
        top: 80px;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        overflow: auto;
      }
      .side-collapse.in {
        width: 0;
        position: fixed;
      }
    }
    @media (min-width: 767px) and (max-width: 1199px) {
      .side-collapse-container.out {
        left: 40%;
      }
      .side-collapse {
        width: 40%;
      }
    }
    .navbar-nav {
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        margin: 7.5px 15px;
      }
      @media screen and (min-width: 992px) {
        float: right;
      }
      >li {
        &.active>a {
          background-color: darken($topnav-bg-color, 5%);
          @media screen and (max-width: $desktop) {
            color: $topnav-link-color-hight;
            background-color: darken($topnav-bg-color, 1%);
          }
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          float: inherit !important;
          width: 100%;
        }
      }
      >li>a {
        color: $topnav-link-color;
        font-size: $topnav-font-size;
        text-transform: uppercase;
        padding: 30px 10px;
        font-family: $font-1;
        font-weight: 600;
        transition: all 0.25s ease-in-out 0s;
        &:focus,
        &:hover {
          color: lighten($topnav-link-color, 50%);
          background-color: darken($topnav-bg-color, 5%);
        }
        @media screen and (max-width: $desktop) {
          line-height: inherit;
          font-weight: lighter;
          text-transform: none;
          font-size: em(10px);
          padding: $md;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          float: inherit !important;
          width: 100%;
          padding: $md $xs;
        }
      }
    }
}
@media screen and (max-width: $tablet-landscape) {
  .side-collapse-container {
    width: 100%;
    position: relative;
    left: 0;
    transition: left .4s;
    -webkit-transition: left .4s;
  }
  .side-collapse {
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    overflow: auto;
  }
  .side-collapse.in {
    width: 0;
    position: fixed;
  }
}

@media screen and (max-width: $tablet) {
  .side-collapse-container.out {
    left: 75%;
  }
  .main-navbar-alianca.out {
    min-height: 100%;
  }
  .side-collapse {
    width: 80%;
    height: 100%;
    position: fixed;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .side-collapse-container.out {
    left: 40%;
  }
  .side-collapse {
    width: 40%;
  }
}

.sa-button-container,
.sa-confirm-button-container {
  button {
    font-family: $font-1;
    font-weight: bold;
    font-size: em(14px);
    border: 0;
  }
}

.main-content,
.main-content-message {
  @extend .container;
  @include clearfix();
  position: relative;
  padding: $md 0;
  @media screen and (max-width: $tablet-landscape) {
    padding: $md 0;
    min-height: inherit;
  }
}

.main-content {
  padding-bottom: 120px;
}

.main-content-message {
  padding-bottom: 0 !important;
}

// .main-content {
//   @media screen and (min-width: $tablet-landscape) {
//     min-height: 560px;
//   }
// }
.main-content-message {
  margin-bottom: $md;
}

.product-list-wrapper, .rastreamento-list-wrapper  {
  @include make-xs-column(12, $gutter: 0);
  @include make-sm-column(12, $gutter: 0);
  @include make-md-column(9.5, $gutter: 0);
  @include make-lg-column(9.5, $gutter: 0);
  @media screen and (max-width: $mobile) {
    margin: 0 $md;
    width: 87%;
  }
  @media (min-width: $mobile) and (max-width: $tablet-landscape) {
    margin: 0 $md;
    width: 93%;
  }
  @media (min-width: 992px) {
    width: 79%;
  }
  @media (min-width: 1200px) {
    width: 83%;
  }
  .panel-footer {
    background-color: transparent;
    border-top: 0;
    margin-top: $md;
  }
}

.product-list-content {
  @media screen and (max-width: 1199px) and (min-width: 991px) {
    margin-bottom: 50px;
  }
}

.product-list {
  $title-color: #838591;
  @include clearfix();
  position: relative;
  padding: 15px 0 15px $sm;
  margin-bottom: $sm;
  background-color: #fff;
  box-shadow: 0px 1px 1px 0px rgba(98, 98, 98, 0.5);
  @media screen and (max-width: $tablet-landscape) {
    padding: 15px 0 35px $sm;
    margin-bottom: $md;
  }
  &_item {
    @media screen and (max-width: $mobile) {
      margin-bottom: $sm;
    }
    .control-label {
      font-family: $font-2;
      font-size: em(11px);
      font-weight: 600;
      text-transform: none;
      color: $title-color;
    }
    .icon {
      position: relative;
      top: 5px;
      color: lighten($title-color, 10%);
    }
  }
  &_details {
    font-family: $font-2;
    font-size: em(12px);
  }
  .local,
  .period {
    @include clearfix();
    font-size: em(13px);
    margin: 0 0 5px;
    @media (min-width: 500px) {
      @include text-overflow();
    }
  }
  .local {
    color: $color-primary;
  }
  .date {
    @include clearfix();
    font-size: em(12px);
    color: $color-primary;
    margin: 0 0 3px;
  }
  .small {
    @include clearfix();
    font-size: em(12px);
    margin: 0 0 2px;
  }
  .n-booking {
    @include text-overflow();
    font-size: em(14px);
    font-weight: bold;
    color: $color-primary;
    margin: $xs 0 0;
    @media screen and (max-width: $tablet-landscape) {
      font-size: em(12px);
    }
  }
  .n-proposta {
    @include text-overflow();
    font-size: em(14px);
    font-weight: bold;
    color: $color-primary;
    margin: 0;
    @media screen and (max-width: $tablet-landscape) {
      font-size: em(12px);
    }
  } // .deadline{
  //   color: $color-primary;
  // }
  p {
    @media (min-width: 500px) {
      @include text-overflow();
    }
    .sub-label {
      font-size: em(12px);
      color: $title-color;
    }
  }
}

.product-list-collapse {
  @include clearfix();
  position: relative;
  margin-top: -10px;
  margin-bottom: $sm;
  background-color: #f7f7f7;
  box-shadow: 0 1px 1px 0 rgba(98, 98, 98, .5);
  .panel-body {
    padding: $md $md $sm;
    font-family: $font-2;
    min-height: 120px;
    .page-loader {
      background-color: #f7f7f7;
      min-height: inherit;
      position: absolute;
      top: -8px;
      border-radius: 0;
    }
  }
  .control-label {
    font-size: em(11px);
  }
  .control-info {
    font-size: em(12px);
    word-wrap: break-word;
  }
  .row-collapse {
    @include clearfix();
    border-bottom: 1px solid #eeeeee;
    padding-bottom: $sm;
    margin-bottom: $sm;
  }
}

.pointer {
  cursor: pointer;
}

.navio {
  margin-bottom: $md;
}

.clearfix {
  @include clearfix();
}

.highlight-content {
  padding: $lg $sm $md;
  margin-bottom: $md;
  background-color: #f4f6f9;
  border-radius: 4px;
  &.view-only {
    label {
      line-height: normal !important;
    }
  }
  .highlight-message {
    color: #365b88;
    font-family: $font-2;
    font-size: em(13px);
    margin-top: $md;
    margin-bottom: 0;
  }
}

.panel {
  position: relative;
}

.panel-settings {
  .panel-body {
    min-height: 300px;
    @media screen and (max-width: $tablet) {
      padding: $sm $xs;
    }
  }
}

.pagination-blank {
  @include clearfix();
  @extend .container;
  margin-bottom: $md;
  .panel-footer {
    background-color: transparent;
    border-top: 0;
  }
}

.pagination>.active>a {
  z-index: 2;
}

.form-group-municipios {
  .form-control-loading {
    top: 33%;
    right: 8%;
  }
}

.text-center {
  text-align: center !important;
}

.datepicker-input,
.datepicker-container,
.datepicker-input-container {
  width: 100% !important;
}

.modal-lg {
  max-width: 800px !important;
}

input[type='text'] {
  min-height: 35px !important;
}
.ali-alterado{
  color: $action-blue !important;
  font-weight: bold !important;
}
