.tp-agendamento-content {
	.tp-container-details {
		/*top: -15px;*/
		position: relative;
	}
}

.modal-agendamento-entrega {

	.header-agendar_entrega {
		@include clearfix();
	  $agendamento-header-bg-color: #7486a5;

	  background-color: $agendamento-header-bg-color;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		padding: 15px $sm $sm;

	  .control-label {
	    line-height: normal;
	    color: #fff;
	    font-size: em(11px);
	  }
	}

	ul {
		li {
			padding: 0 $sm;

			.icon{
				top:12px;
			}
		}
	}

	.col_ae{
		
		&_status{
			@include make-xs-column(6, $gutter: 0 );
			@include make-sm-column(0.5, $gutter: 0);
			.icon-forbidden{
				color:$color-danger;
			}
			.icon-check{
				color:$color-success;
			}
			
			.icon-alert{
				color:$color-warning;
			}
			
		}
		&_id{
			@include make-xs-column(6, $gutter: 0);
			@include make-sm-column(0.5, $gutter: 0);
		}
		&_conteiner{
			@include make-xs-column(12, $gutter: 0);
			@include make-sm-column(3.5, $gutter: 0);
		}
		&_referencia{
			@include make-xs-column(12, $gutter: $xs $xs $xs);
			@include make-sm-column(2.3, $gutter: $xs $xs $xs);
		}
		&_data_entrega{
			@include make-xs-column(6, $gutter: $xs $xs $xs);
			@include make-sm-column(2, $gutter: $xs $xs $xs);
			.datepicker-input{
				min-height: 40px !important;
			}
		}
		&_horario{
			@include make-xs-column(6, $gutter: $xs $xs $xs);
			@include make-sm-column(2, $gutter: $xs $xs $xs);
		
		}
		&_transport{
			@include make-xs-column(4, $gutter: 0);
			@include make-sm-column(1.2, $gutter: 0);
			line-height: 40px;
			vertical-align: bottom;
			text-align: center;
			i{
			font-size: 1.5em;
			color: #59639f;
			}
			.tooltip{
			
			}
		}
	}

	.tp-agendamento-content {
	  padding: 15px $sm;
	}

	.tp-agendamento-destinatario {
		.control-label {
			text-transform: inherit;
			font-size: em(13px);
		}
	}

	.tp-agendamento-content,
	.tp-agendamento-destinatario,
	.tp-agendamento-observacoes {
	  @include clearfix();
	  border-bottom: 1px solid #f5f5f5;
	  font-size: em(12px);

		.panel-title {
			font-size: em(15px);
			font-weight: 600;
			font-family: $font-1;
		}

	  .form-control {
	    height: 40px;
	    border-color: #cbd5e7;
	  }

	  .datepicker-input-container {
	    width: 75px;

	    @media screen and (max-width: $desktop) {
	      width: 100%;
	    }
	  }

		.col_ae_conteiner {
			.tp-container-title {
				// font-family: $font-2;
				// font-weight: 400;
				// font-size: em(13px);
				/*display: none;*/
			}

			span{
				font-family: $font-2;
				font-weight: 400;
				font-size: em(11px);
			}

			.tp-container-title {}
		}

		&.col_ae {
			&_id {
				border-right: 1px solid $input-border-color;
			}
		}
	}

}
