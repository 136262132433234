.tp-s {
  &_embarque {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(2.3, $gutter: 15px);
    @include make-lg-column(2.3, $gutter: 15px);
  }

  &_desembarque {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(2.3, $gutter: 15px);
    @include make-lg-column(2.3, $gutter: 15px);
  }

  &_navio_viagem {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(1.8, $gutter: 15px);
    @include make-lg-column(1.8, $gutter: 15px);
  }

  &_transit_time {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(1.7, $gutter: 15px);
    @include make-lg-column(1.7, $gutter: 15px);
  }

  &_deadline {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(1.9, $gutter: 15px);
    @include make-lg-column(1.9, $gutter: 15px);
  }

  &_booking {
    @include make-xs-column(12, $gutter: 15px);
    @include make-sm-column(12, $gutter: 15px);
    @include make-md-column(1.6, $gutter: 15px);
    @include make-lg-column(1.6, $gutter: 15px);

    text-align: center;

    .btn-booking {
      // width: 85%;
      min-height: inherit;
      border-radius: 20px;
      font-size: em(11px);
      transition: box-shadow .2s ease,border .2s ease;
      padding: 6px $sm;
      margin-top: 15px;

      &:hover {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .25);
      }
    }
  }
}

.btn-transbordo {
  font-family: $font-1;
  border-radius: 20px;
  border-color: #ccddf3;
  font-size: em(11px);
  font-weight: normal;
  padding: 4px 12px;
  min-height: inherit;

  .icon {
    top: 1px;
    left: -2px;
    color: #ccddf3;
  }
}

.tp-s_navio_viagem {
  @media (min-width: 992px){
    width: 18%!important;
  }

  popover-container {
    min-width: 320px;

    h5 {
      font-family: $font-1;
      font-size: em(12px);
      font-weight: 500;
    }

    p {
      font-family: $font-2;
      font-size: em(12px);
      margin-bottom: 5px;
    }

    .popover-title {
      font-family: $font-1;
      font-size: em(14px);
    }

    .popover-content {
      padding: $sm 15px $md;
    }

    .control-label {
      font-size: em(12px);
      min-width: 120px;
      display: inline-block;
    }
  }
}
