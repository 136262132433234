// Helpers - margins and paddings
$zero: 0;
$xs: 5px;
$sm: 10px;
$md: 20px;
$lg: 30px;
$xlg: 40px;

/* Colors helpers */

.bg-danger {
  background-color: $color-danger !important;
}

.bg-success {
  background-color: $color-success !important;
}


/* Texts helpers */

.text-black {
  color: #21292c !important;
}

.text-white {
  color: #fff !important;
}


/* --------- All --------- */

.m-all-0 {
  @include space(margin, all, $zero);
}

.m-all-xs {
  @include space(margin, all, $xs);
}

.m-all-sm {
  @include space(margin, all, $sm);
}

.m-all-md {
  @include space(margin, all, $md);
}

.m-all-lg {
  @include space(margin, all, $lg);
}


/* --------- Top --------- */

.m-top-0 {
  @include space(margin, top, $zero !important);
}

.m-top-xs {
  @include space(margin, top, $xs !important);
}

.m-top-sm {
  @include space(margin, top, $sm !important);
}

.m-top-md {
  @include space(margin, top, $md !important);
}

.m-top-lg {
  @include space(margin, top, $lg);
}

.m-top-xlg {
  @include space(margin, top, $xlg);
}


/* --------- Right --------- */

.m-right-0 {
  @include space(margin, right, $zero);
}

.m-right-xs {
  @include space(margin, right, $xs);
}

.m-right-sm {
  @include space(margin, right, $sm);
}

.m-right-md {
  @include space(margin, right, $md !important);
}

.m-right-lg {
  @include space(margin, right, $lg);
}


/* --------- Bottom --------- */

.m-bottom-0 {
  @include space(margin, bottom, $zero !important);
}

.m-bottom-xs {
  @include space(margin, bottom, $xs !important);
}

.m-bottom-sm {
  @include space(margin, bottom, $sm !important);
}

.m-bottom-md {
  @include space(margin, bottom, $md !important);
}

.m-bottom-lg {
  @include space(margin, bottom, $lg !important);
}

.m-bottom-xlg {
  @include space(margin, bottom, $xlg !important);
}


/* --------- Left --------- */

.m-left-0 {
  @include space(margin, left, $zero !important);
}

.m-left-xs {
  @include space(margin, left, $xs !important);
}

.m-left-sm {
  @include space(margin, left, $sm !important);
}

.m-left-md {
  @include space(margin, left, $md !important);
}

.m-left-lg {
  @include space(margin, left, $lg !important);
}


/* --------- Padding --------- */


/* --------- All --------- */

.p-all-0 {
  @include space(padding, all, $zero);
}

.p-all-xs {
  @include space(padding, all, $xs);
}

.p-all-sm {
  @include space(padding, all, $sm);
}

.p-all-md {
  @include space(padding, all, $md);
}

.p-all-lg {
  @include space(padding, all, $lg);
}

.p-all-xlg {
  @include space(padding, all, $xlg);
}


/* --------- Top --------- */

.p-top-0 {
  @include space(padding, top, $zero !important);
}

.p-top-xs {
  @include space(padding, top, $xs !important);
}

.p-top-sm {
  @include space(padding, top, $sm !important);
}

.p-top-md {
  @include space(padding, top, $md !important);
}

.p-top-lg {
  @include space(padding, top, $lg);
}

.p-top-xlg {
  @include space(padding, top, $xlg);
}


/* --------- Right --------- */

.p-right-0 {
  @include space(padding, right, $zero);
}

.p-right-xs {
  @include space(padding, right, $xs);
}

.p-right-sm {
  @include space(padding, right, $sm);
}

.p-right-md {
  @include space(padding, right, $md);
}

.p-right-lg {
  @include space(padding, right, $lg);
}

.p-right-xlg {
  @include space(padding, right, $xlg);
}


/* --------- Bottom --------- */

.p-bottom-0 {
  @include space(padding, bottom, $zero !important);
}

.p-bottom-xs {
  @include space(padding, bottom, $xs !important);
}

.p-bottom-sm {
  @include space(padding, bottom, $sm !important);
}

.p-bottom-md {
  @include space(padding, bottom, $md !important);
}

.p-bottom-lg {
  @include space(padding, bottom, $lg);
}

.p-bottom-xlg {
  @include space(padding, bottom, $xlg);
}

.p-bottom-120 {
  @include space(padding, bottom, 120px !important);
}


/* --------- Left --------- */

.p-left-0 {
  @include space(padding, left, $zero);
}

.p-left-xs {
  @include space(padding, left, $xs);
}

.p-left-sm {
  @include space(padding, left, $sm);
}

.p-left-md {
  @include space(padding, left, $md);
}

.p-left-lg {
  @include space(padding, left, $lg);
}

.p-left-xlg {
  @include space(padding, left, $xlg);
}


/* --------- Border --------- */

.b-r-0 {
  border-right: 0 !important;
}


/* --------- after --------- */

.a-r-0 {
  &:after {
    content: "" !important;
  }
}

// Equal height
.equal,
.equal>div[class*='col-'] {
  @media screen and (min-width: 992px) {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    flex: 1 1 auto; // display: -ms-flexbox;
    // display: flex;
  }
}

// Remove o X tosco que só aparece no IE nos inputs
::-ms-clear {
  display: none;
}


/* Min element size
------------------------------------
*/

.nomin-h {
  min-height: none !important;
}

.nomin-w {
  min-width: none !important;
}

.mn-h-10 {
  min-height: 10px !important;
}

.mn-w-10 {
  min-width: 10px !important;
}

.mn-h-20 {
  min-height: 20px !important;
}

.mn-w-20 {
  min-width: 20px !important;
}

.mn-h-30 {
  min-height: 30px !important;
}

.mn-w-30 {
  min-width: 30px !important;
}

.mn-h-35 {
  min-height: 35px !important;
}

.mn-w-35 {
  min-width: 35px !important;
}

.mn-h-40 {
  min-height: 40px !important;
}

.mn-w-40 {
  min-width: 40px !important;
}

.mn-h-50 {
  min-height: 50px !important;
}

.mn-w-50 {
  min-width: 50px !important;
}


/* Max element size
------------------------------------
*/

.nomax-h {
  max-height: none !important;
}

.nomax-w {
  max-width: none !important;
}

.mx-h-10 {
  max-height: 10px !important;
}

.mx-w-10 {
  max-width: 10px !important;
}

.mx-h-20 {
  max-height: 20px !important;
}

.mx-w-20 {
  max-width: 20px !important;
}

.mx-w-30 {
  max-width: 30px !important;
}

.mx-h-30 {
  max-height: 30px !important;
}

.mx-h-40 {
  max-height: 40px !important;
}

.mx-w-40 {
  max-width: 40px !important;
}

.mx-h-50 {
  max-height: 50px !important;
}

.mx-w-50 {
  max-width: 50px !important;
}

.mx-h-60 {
  max-height: 60px !important;
}

.mx-w-60 {
  max-width: 60px !important;
}

.mx-h-70 {
  max-height: 70px !important;
}

.mx-w-70 {
  max-width: 70px !important;
}

.mx-h-80 {
  max-height: 80px !important;
}

.mx-w-80 {
  max-width: 80px !important;
}

.mx-h-90 {
  max-height: 90px !important;
}

.mx-w-90 {
  max-width: 90px !important;
}

.mx-h-100 {
  max-height: 100px !important;
}

.mx-w-100 {
  max-width: 100px !important;
}

.mx-h-200 {
  max-height: 200px !important;
}

.mx-w-200 {
  max-width: 200px !important;
}

.mx-h-300 {
  max-height: 300px !important;
}

.mx-w-300 {
  max-width: 300px !important;
}

.mx-w-350 {
  max-width: 350px !important;
}

.mx-h-400 {
  max-height: 400px !important;
}

.mx-w-400 {
  max-width: 400px !important;
}

.mx-h-500 {
  max-height: 500px !important;
}

.mx-w-500 {
  max-width: 500px !important;
}

.mx-h-100p {
  max-height: 100% !important;
}

.mx-w-100p {
  max-width: 100% !important;
}


/* Element size
------------------------------------
*/

.height-10 {
  height: 10px !important;
}

.width-10 {
  width: 10px !important;
}

.height-20 {
  height: 20px !important;
}

.width-20 {
  width: 20px !important;
}

.height-30 {
  height: 30px !important;
}

.width-30 {
  width: 30px !important;
}

.height-35 {
  height: 35px !important;
}

.width-35 {
  width: 35px !important;
}

.height-40 {
  height: 40px !important;
}

.width-40 {
  width: 40px !important;
}

.height-50 {
  height: 50px !important;
}

.width-50 {
  width: 50px !important;
}

.height-100p {
  height: 100% !important;
}

.width-100p {
  width: 100% !important;
}


/* Padding Helpers
------------------------------------
*/

.padding-inherit {
  padding: inherit !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.padding-0 {
  padding: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.p-t-9 {
  padding-top: 9px !important;
}

.p-r-9 {
  padding-right: 9px !important;
}

.p-l-9 {
  padding-left: 9px !important;
}

.p-b-9 {
  padding-bottom: 9px !important;
}

.padding-9 {
  padding: 9px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.padding-20 {
  padding: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.padding-25 {
  padding: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.padding-30 {
  padding: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.padding-35 {
  padding: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.padding-40 {
  padding: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.padding-45 {
  padding: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.padding-50 {
  padding: 50px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.padding-55 {
  padding: 55px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.padding-60 {
  padding: 60px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.padding-65 {
  padding: 65px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.padding-70 {
  padding: 70px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.padding-75 {
  padding: 75px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.padding-80 {
  padding: 80px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.padding-85 {
  padding: 85px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.padding-90 {
  padding: 90px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.padding-95 {
  padding: 95px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.padding-100 {
  padding: 100px !important;
}


/* Margin Helpers
------------------------------------
*/

.margin-inherit {
  margin: inherit;
}

.m-t-0 {
  margin-top: 0px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.margin-0 {
  margin: 0;
}

.m-t-5 {
  margin-top: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.margin-5 {
  margin: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.margin-10 {
  margin: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-r-55 {
  margin-right: 55px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-b-55 {
  margin-bottom: 55px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-r-65 {
  margin-right: 65px;
}

.m-l-65 {
  margin-left: 65px;
}

.m-b-65 {
  margin-bottom: 65px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-t-75 {
  margin-top: 75px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-b-75 {
  margin-bottom: 75px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-r-80 {
  margin-right: 80px;
}

.m-l-80 {
  margin-left: 80px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-t-85 {
  margin-top: 85px;
}

.m-r-85 {
  margin-right: 85px;
}

.m-l-85 {
  margin-left: 85px;
}

.m-b-85 {
  margin-bottom: 85px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-l-90 {
  margin-left: 90px;
}

.m-b-90 {
  margin-bottom: 90px;
}

.m-t-95 {
  margin-top: 95px;
}

.m-r-95 {
  margin-right: 95px;
}

.m-l-95 {
  margin-left: 95px;
}

.m-b-95 {
  margin-bottom: 95px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-r-100 {
  margin-right: 100px;
}

.m-l-100 {
  margin-left: 100px;
}

.m-b-100 {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .sm-pull-bottom,
  .sm-pull-up {
    position: relative !important;
  }
  .sm-p-t-0 {
    padding-top: 0px !important;
  }
  .sm-p-r-0 {
    padding-right: 0px !important;
  }
  .sm-p-l-0 {
    padding-left: 0px !important;
  }
  .sm-p-b-0 {
    padding-bottom: 0px !important;
  }
  .sm-padding-0 {
    padding: 0px !important;
  }
  .sm-p-t-5 {
    padding-top: 5px !important;
  }
  .sm-p-r-5 {
    padding-right: 5px !important;
  }
  .sm-p-l-5 {
    padding-left: 5px !important;
  }
  .sm-p-b-5 {
    padding-bottom: 5px !important;
  }
  .sm-padding-5 {
    padding: 5px !important;
  }
  .sm-p-t-10 {
    padding-top: 10px !important;
  }
  .sm-p-r-10 {
    padding-right: 10px !important;
  }
  .sm-p-l-10 {
    padding-left: 10px !important;
  }
  .sm-p-b-10 {
    padding-bottom: 10px !important;
  }
  .sm-padding-10 {
    padding: 10px !important;
  }
  .sm-p-t-15 {
    padding-top: 15px !important;
  }
  .sm-p-r-15 {
    padding-right: 15px !important;
  }
  .sm-p-l-15 {
    padding-left: 15px !important;
  }
  .sm-p-b-15 {
    padding-bottom: 15px !important;
  }
  .sm-padding-15 {
    padding: 15px !important;
  }
  .sm-p-t-20 {
    padding-top: 20px !important;
  }
  .sm-p-r-20 {
    padding-right: 20px !important;
  }
  .sm-p-l-20 {
    padding-left: 20px !important;
  }
  .sm-p-b-20 {
    padding-bottom: 20px !important;
  }
  .sm-padding-20 {
    padding: 20px !important;
  }
  .sm-p-t-25 {
    padding-top: 25px !important;
  }
  .sm-p-r-25 {
    padding-right: 25px !important;
  }
  .sm-p-l-25 {
    padding-left: 25px !important;
  }
  .sm-p-b-25 {
    padding-bottom: 25px !important;
  }
  .sm-padding-25 {
    padding: 25px !important;
  }
  .sm-p-t-30 {
    padding-top: 30px !important;
  }
  .sm-p-r-30 {
    padding-right: 30px !important;
  }
  .sm-p-l-30 {
    padding-left: 30px !important;
  }
  .sm-p-b-30 {
    padding-bottom: 30px !important;
  }
  .sm-padding-30 {
    padding: 30px !important;
  }
  .sm-p-t-35 {
    padding-top: 35px !important;
  }
  .sm-p-r-35 {
    padding-right: 35px !important;
  }
  .sm-p-l-35 {
    padding-left: 35px !important;
  }
  .sm-p-b-35 {
    padding-bottom: 35px !important;
  }
  .sm-padding-35 {
    padding: 35px !important;
  }
  .sm-p-t-40 {
    padding-top: 40px !important;
  }
  .sm-p-r-40 {
    padding-right: 40px !important;
  }
  .sm-p-l-40 {
    padding-left: 40px !important;
  }
  .sm-p-b-40 {
    padding-bottom: 40px !important;
  }
  .sm-padding-40 {
    padding: 40px !important;
  }
  .sm-p-t-45 {
    padding-top: 45px !important;
  }
  .sm-p-r-45 {
    padding-right: 45px !important;
  }
  .sm-p-l-45 {
    padding-left: 45px !important;
  }
  .sm-p-b-45 {
    padding-bottom: 45px !important;
  }
  .sm-padding-45 {
    padding: 45px !important;
  }
  .sm-p-t-50 {
    padding-top: 50px !important;
  }
  .sm-p-r-50 {
    padding-right: 50px !important;
  }
  .sm-p-l-50 {
    padding-left: 50px !important;
  }
  .sm-p-b-50 {
    padding-bottom: 50px !important;
  }
  .sm-padding-50 {
    padding: 50px !important;
  }
  .sm-m-t-5 {
    margin-top: 5px !important;
  }
  .sm-m-r-5 {
    margin-right: 5px !important;
  }
  .sm-m-l-5 {
    margin-left: 5px !important;
  }
  .sm-m-b-5 {
    margin-bottom: 5px !important;
  }
  .sm-m-t-10 {
    margin-top: 10px !important;
  }
  .sm-m-r-10 {
    margin-right: 10px !important;
  }
  .sm-m-l-10 {
    margin-left: 10px !important;
  }
  .sm-m-b-10 {
    margin-bottom: 10px !important;
  }
  .sm-m-t-15 {
    margin-top: 15px !important;
  }
  .sm-m-r-15 {
    margin-right: 15px !important;
  }
  .sm-m-l-15 {
    margin-left: 15px !important;
  }
  .sm-m-b-15 {
    margin-bottom: 15px !important;
  }
  .sm-m-t-20 {
    margin-top: 20px !important;
  }
  .sm-m-r-20 {
    margin-right: 20px !important;
  }
  .sm-m-l-20 {
    margin-left: 20px !important;
  }
  .sm-m-b-20 {
    margin-bottom: 20px !important;
  }
  .sm-m-t-25 {
    margin-top: 25px !important;
  }
  .sm-m-r-25 {
    margin-right: 25px !important;
  }
  .sm-m-l-25 {
    margin-left: 25px !important;
  }
  .sm-m-b-25 {
    margin-bottom: 25px !important;
  }
  .sm-m-t-30 {
    margin-top: 30px !important;
  }
  .sm-m-r-30 {
    margin-right: 30px !important;
  }
  .sm-m-l-30 {
    margin-left: 30px !important;
  }
  .sm-m-b-30 {
    margin-bottom: 30px !important;
  }
  .sm-m-t-35 {
    margin-top: 35px !important;
  }
  .sm-m-r-35 {
    margin-right: 35px !important;
  }
  .sm-m-l-35 {
    margin-left: 35px !important;
  }
  .sm-m-b-35 {
    margin-bottom: 35px !important;
  }
  .sm-m-t-40 {
    margin-top: 40px !important;
  }
  .sm-m-r-40 {
    margin-right: 40px !important;
  }
  .sm-m-l-40 {
    margin-left: 40px !important;
  }
  .sm-m-b-40 {
    margin-bottom: 40px !important;
  }
  .sm-m-t-45 {
    margin-top: 45px !important;
  }
  .sm-m-r-45 {
    margin-right: 45px !important;
  }
  .sm-m-l-45 {
    margin-left: 45px !important;
  }
  .sm-m-b-45 {
    margin-bottom: 45px !important;
  }
  .sm-m-t-50 {
    margin-top: 50px !important;
  }
  .sm-m-r-50 {
    margin-right: 50px !important;
  }
  .sm-m-l-50 {
    margin-left: 50px !important;
  }
  .sm-m-b-50 {
    margin-bottom: 50px !important;
  }
  .sm-no-margin {
    margin: 0px !important;
  }
  .sm-no-padding {
    padding: 0px !important;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-pull-right {
    float: right !important;
  }
  .sm-pull-left {
    float: left !important;
  }
  .sm-pull-reset {
    float: none !important;
  }
  .sm-block {
    display: block;
  }
}

.sm-image-responsive-height {
  width: 100%;
  height: auto;
}

.error-container {
  width: auto;
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100%;
}

.hide {
  display: none;
}

.inline {
  display: inline-block !important;
}

.block {
  display: block;
}


/* Border Helpers
------------------------------------
*/

.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0;
}

.b-r {
  border-right-width: 1px;
}

.b-l {
  border-left-width: 1px;
}

.b-t {
  border-top-width: 1px;
}

.b-b {
  border-bottom-width: 1px;
}

.b-a {
  border-width: 1px;
}

.b-dashed {
  border-style: dashed;
}

.b-thick {
  border-width: 2px;
}

.b-transparent {
  border-color: rgba(0, 0, 0, 0.4);
}

.b-transparent-white {
  border-color: rgba(255, 255, 255, 0.3);
}

.b-grey {
  border-color: #dbdee0;
}

.b-white {
  border-color: #fff;
}

.b-primary {
  border-color: #5d598d;
}

.b-complete {
  border-color: #5d598d;
}

.b-success {
  border-color: #2eaeb3;
}

.b-info {
  border-color: #1f3853;
}

.b-danger {
  border-color: #e95555;
}

.b-warning {
  border-color: #fbc25e;
}


/* Border Radius Helpers
------------------------------------
*/

.b-rad-tl-0 {
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
}

.b-rad-tr-0 {
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
}

.b-rad-bl-0 {
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.b-rad-br-0 {
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.b-rad-tl-1 {
  -webkit-border-top-left-radius: 1px !important;
  -moz-border-radius-topleft: 1px !important;
  border-top-left-radius: 1px !important;
}

.b-rad-tr-1 {
  -webkit-border-top-right-radius: 1px !important;
  -moz-border-radius-topright: 1px !important;
  border-top-right-radius: 1px !important;
}

.b-rad-bl-1 {
  -webkit-border-bottom-left-radius: 1px !important;
  -moz-border-radius-bottomleft: 1px !important;
  border-bottom-left-radius: 1px !important;
}

.b-rad-br-1 {
  -webkit-border-bottom-right-radius: 1px !important;
  -moz-border-radius-bottomright: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.b-rad-tl-2 {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
}

.b-rad-tr-2 {
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
}

.b-rad-bl-2 {
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.b-rad-br-2 {
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.b-rad-tl-3 {
  -webkit-border-top-left-radius: 3px !important;
  -moz-border-radius-topleft: 3px !important;
  border-top-left-radius: 3px !important;
}

.b-rad-tr-3 {
  -webkit-border-top-right-radius: 3px !important;
  -moz-border-radius-topright: 3px !important;
  border-top-right-radius: 3px !important;
}

.b-rad-bl-3 {
  -webkit-border-bottom-left-radius: 3px !important;
  -moz-border-radius-bottomleft: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.b-rad-br-3 {
  -webkit-border-bottom-right-radius: 3px !important;
  -moz-border-radius-bottomright: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.b-rad-tl-50 {
  -webkit-border-top-left-radius: 50% !important;
  -moz-border-radius-topleft: 50% !important;
  border-top-left-radius: 50% !important;
}

.b-rad-tr-50 {
  -webkit-border-top-right-radius: 50% !important;
  -moz-border-radius-topright: 50% !important;
  border-top-right-radius: 50% !important;
}

.b-rad-bl-50 {
  -webkit-border-bottom-left-radius: 50% !important;
  -moz-border-radius-bottomleft: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.b-rad-br-50 {
  -webkit-border-bottom-right-radius: 50% !important;
  -moz-border-radius-bottomright: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.b-rad-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -oz-border-radius: 3px;
}

.b-rad-md {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.b-rad-lg {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
}

.no-border {
  border: none !important;
}


/* Opacity helpers */

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}
