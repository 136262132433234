$verde-claro:#57a5a4;
$azul: #0061a6;
$cinza: #002b5c;
$roxo:#8e6cd5;
$laranja:#ff7e00;
$verde:#2ea94b;
.all-cargas {
  i,
  .total {
    font-size: 1.5em;
  }
  p {
    margin-top: $xs;
    font-size: 0.7em;
  }
}

.steps-wrapper {
  margin-bottom: $sm;
  .dropdown {
    padding: 0 10px 20px;
  }
  .dropdown-menu {
    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
    }
  }
  .steps-cargas {
    display: flex;
    li {
      display: inline-block;
      font-size: 2em;
      padding: $xs;
      position: relative;
      margin-right: 15px;
      opacity: 0.3;
      @include make-xs-column(12, $gutter: 0px);
      @include make-sm-column(1.20, $gutter: 0px);
      @include make-md-column(1.20, $gutter: 0px);
      @include make-lg-column(1.20, $gutter: 0px);
      a {
        padding: $xs;
        display: table;
        &.active {
          background-color: #fff;
          border: #ccc 1px solid;
          border-radius: 5px;
        }
      }
      .label {
        position: absolute;
        right: 10px;
        top: 0;
        background: $color-danger;
        font-size: 10px;
        width: $md;
        height: $md;
        border-radius: 100%;
        padding: 0;
        line-height: $md;
        z-index: 1;
      }
      .icon {
        float: left;
        margin-top: $xs;
        opacity: 1;
        width: 35%;
        text-align: center;
        font-size: 0.8em;
      }
      .total {
        float: left;
        letter-spacing: -1px;
        opacity: 1;
        width:calc(100% - 35%);
        text-align: center;
        font-family: $font-1;

      }
      .legenda {
        font-size: 0.35em;
        display: block;
        float: left;
        width: 100%;
        text-align: center;
        opacity: 1;
        font-family: $font-2;
        font-weight: 400;
        position: relative;
        top: -5px;
      }
      &.possui-itens {
        opacity: 1;
      }
      &.step-total {
        width: 14%;
        margin-right: 0;
        a {
          color: black;
        }
      }
      &.step-confirmadas {
        color: $verde-claro;
        a {
          color: $verde-claro;
        }
      }

      &.step-coletadas {
        color: #981e1e;
        a {
          color: #981e1e;
        }
      }
      &.step-navegando {
        color: $cinza;
        a {
          color: $cinza;
        }
      }
      &.step-depositadas {
        color: $azul;
        a {
          color: $azul;
        }
      }
      &.step-desembarcadas {
        color: $roxo;
        a {
          color: $roxo;
        }
      }
      &.step-em-entregas {
        color: $laranja;
        margin-left: 15px;
        a {
          color: $laranja;
        }
      }
      &.step-realizadas {
        color: $verde;
        a {
          color: $verde;
        }
      }
      &.step-retirada-cheio {
        color: $cinza;
        a {
          color: $cinza;
        }
        .icon-truck-3{
          transform: scaleX(1);
        }
      }
      &.step-devolucao-vazio{
        color: #981e1e;
        a {
          color: #981e1e;
        }
        .icon-devolucao-vazio{
          width: 23px;
          height:23px;
          margin-right: 2px;
        }
      }
      .icon-next {
        position: absolute;
        right: -20%;
        top: $md;
        font-size: 0.7em;
      }
    }
  }
}
