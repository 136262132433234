/*------------------------------------------------------------*\
    TOPNAV
\*------------------------------------------------------------*/

$topnav-height: 80px;
$topnav-bg-color: $color-primary;
$topnav-link-color: #fff;
$topnav-link-color-hight: #ffdc00;
$topnav-font-size: em(10.5px);
$topnav-user-height: 25px;
$topnav-user-bg-color: #000;
$topnav-user-font-size: em(12px);
$topnav-user-font-size-mobile: em(16px);
.main-navbar-alianca {
  @extend .navbar;
  margin-bottom: 0;
  z-index: 1050;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .26), 0 -1px 0 rgba(0, 0, 0, .02);
  border-radius: 0;
  border-bottom: 0;
  @media screen and (min-width: 992px) {
    background-color: $topnav-bg-color;
    min-height: $topnav-height;
  }
  @media screen and (max-width: $tablet-landscape) {
    overflow: hidden;
    position: fixed;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
  }
  .container {
    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
      padding: 0;
    }
  }
  .navbar-header {
    background-color: $topnav-bg-color;
    @media screen and (max-width: $tablet-landscape) {
      height: 50px;
      width: 100%;
      margin: 0;
    }
  }
  .login-message {
    font-size: em(12px);
    display: inline-block;
    margin-top: 2px;
    margin-right: $xs;
    color: rgba($topnav-link-color, .74);
  }
  .logged {
    margin-top: 1px;
  }
  .login-user {
    font-size: em(13px);
    position: relative;
    top: 1px;
    margin-right: 3px;
  }
  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: $tablet-landscape) {
      background-color: $topnav-bg-color;
    }
  }
  &.peril-admin{
    .navbar-collapse {
      padding-left: 0;
      padding-right: 0;
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        background-color: $topnav-bg-color;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .navbar-brand{
        padding: 15px 15px;
      }
      .navbar-toggle {
        display: block;
        position: relative; // left: -25px;
      }
    }
    .icon-mobile {
      
      @media screen and (min-width: 991px) and (max-width: 1200px) {
        display: block;
      }
    }
  }
  .navbar-brand {
    @include clearfix();
    /*padding: 30px 15px 25px 15px;*/
    @media screen and (max-width: $tablet-landscape) {
      padding: 0;
      width: calc(85% - 81px);
      height: inherit;
    }
    @media screen and (max-width: $mobile) {
    
      width: calc(85% - 98px);
     
    }
    img {
      width: 100%;
      max-width: 160px;
      @media screen and (max-width: $tablet-landscape) {
        max-width: 100px;
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  }
  .break{
    display:none;
  }
  .navbar-toggle {
    float: left;
    margin-left: $sm;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    .icon-bar {
      width: 25px;
      background-color: $topnav-link-color;
    }
    .icon-bar+.icon-bar {
      margin-top: 5px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      display: block;
      position: relative; // left: -25px;
    }
  }
  .navbar-nav {
    @media screen and (max-width: $tablet-landscape) {
      margin: 7.5px 15px;
    }
    @media screen and (min-width: 992px) {
      float: right;
    }
    >li {
      &.active>a {
        background-color: $color-primary-active;
        @media screen and (max-width: $tablet-landscape) {
          color: $topnav-link-color-hight;
          background-color: $color-primary-active;
        }
      }
      @media (min-width: 768px) and (max-width: 991px) {
        float: inherit !important;
        width: 100%;
      }
    }
    >li>a {
      color: $topnav-link-color;
      font-size: $topnav-font-size;
      text-transform: uppercase;
      padding: 30px 10px;
      font-family: $font-3;
      font-weight: 600;
      transition: all 0.25s ease-in-out 0s;
      &:focus,
      &:hover {
        color: lighten($topnav-link-color, 50%);
        background-color: darken($topnav-bg-color, 5%);
      }
      @media screen and (max-width: $tablet-landscape) {
        line-height: inherit;
        font-weight: lighter;
        text-transform: none;
        font-size: em(10px);
        padding: $md;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        float: inherit !important;
        width: 100%;
        padding: $md $xs;
      }
    }
    &.padding-small{
      text-align: right;
      >li{
        float: none;
        display: inline-block;
          a {
          padding: 10px 10px;
        }
      }
      .break{
        display: block;
      }
      .divider{
        display: none;
      }
    }
  }
  .navbar-user,
  .navbar-user-mobile {
    .dropdown-menu {
      font-family: $font-3;
      margin-top: $sm;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, .1);
      z-index: 99999 !important;
      position: absolute;
      li>a {
        font-size: em(12px);
        padding: $sm;
      }
      .divider-drop {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
      }
    }
  }
  .navbar-user-mobile {
    .dropdown-menu {
      position: fixed;
      top: 40px;
    }
  }
  .divider {
    @include clearfix();
    width: 100%;
    height: 1px;
    background-color: rgba($topnav-link-color, .14);
    margin-top: -5px;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  .user {
    font-size: em(18px);
    font-weight: bold;
  }
  .mail {
    font-size: em(12px);
    font-weight: lighter;
  }
  .icon-mobile {
    font-size: em(20px);
    float: left;
    margin-right: $md;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  .navbar-user {
    width: 100.5%; // fix it ((ie10 hack))
    margin-left: -1px;
    min-height: $topnav-user-height;
    background-color: $topnav-user-bg-color;
    text-align: right;
    .dropdown-toggle {
      margin-top: $xs;
      color: $topnav-link-color;
      font-size: $topnav-user-font-size;
      font-family: $font-3;
      font-weight: 500;
      transition: all 0.25s ease-in-out 0s;
      i {
        position: relative;
        top: 2px;
      }
      &:hover {
        color: darken($topnav-link-color, 20%);
      }
    }
  }
  .navbar-user-mobile {
    .dropdown{
      a{
        display: inline-block;
      }
    }
    .dropdown-toggle {
      display: block;
      margin-top: 17px;
      margin-right: 10px;
      color: $topnav-link-color;
      font-size: $topnav-user-font-size-mobile;
      font-family: $font-3;
      font-weight: 500;
      transition: all 0.25s ease-in-out 0s;
      width: 20px;
      height: 20px;
      &:hover {
        color: darken($topnav-link-color, 20%);
      }
    }
  }
}

.dropdown-menu-admin {
  z-index: 8 !important;
  .title {
    color: darken($topnav-link-color, 40%);
    font-size: em(10px);
    margin-left: $sm;
    font-weight: 700;
    font-family: $font-3;
    text-transform: uppercase;
  }
  padding: $sm;
  min-width: 220px;
}
.grupo-economico{
  font-size: em(11px);
 
  position: relative;
  top: 1px;
  margin-right: 3px;
  color:$topnav-link-color;
  margin-right: $md;
    padding-right: $md;
    border-right: 1px solid #fff;
    @media screen and (max-width: 992px) { 
      font-size: 1em;
      margin-top: 17px;
      margin-right: 0;
    padding-right: 10px;
    }
  &:hover{
    color: lighten($topnav-link-color, 50%);
  }
 
}