.modal-finalizar-os {
  font-size: 11px;
  .modal {
    padding-right: 0 !important;
  }
  .alert {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    font-size: 13px;
  }
  .modal-body {
    background-color: #f4f8fb;
  }
  .panel {
    box-shadow: none;
    background-color: inherit;
  }
  .panel-body .form-control-feedback.icon {
    top: 7px;
    right: 17px;
  }
  .panel-body .form-group .control-label {
    padding-top: 10px;
    height: 45px;
  }
  .custom-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #7486a5;
    color: #ffffff;
  }
  .input-icon {
    padding: 0 !important;
    .form-control {
      max-height: 35px;
      width: 90%;
    }
    .icon {
      top: 9px;
      right: 20px;
    }
  }
  .fechar-ordem {
    margin: 0;
    padding: 10px 0 5px 0;
    min-height: 50px;
    background: #fff;
    border-bottom: 1px solid #efefef;
    @media (max-width: 991px) {
      max-height: none !important;
    }
  }
  .fechar-ordem:last-of-type {
    border-radius: 0 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }
  .coluna {
    &-indice,
    &-servico,
    &-programacao,
    &-embarcador,
    &-municipio,
    &-ordem,
    &-container,
    &-inicio,
    &-fim,
    &-data,
    &-hora {
      @include make-xs-column(12, $gutter: 10px);
      @include make-sm-column(6, $gutter: 10px);
    }
    &-indice {
      @include make-md-column(0.3, $gutter: 10px);
      @include make-lg-column(0.3, $gutter: 10px);
    }
    &-servico {
      @include make-md-column(1, $gutter: 10px);
      @include make-lg-column(1, $gutter: 10px);
    }
    &-programacao {
      @include make-md-column(1.5, $gutter: 10px);
      @include make-lg-column(1.5, $gutter: 10px);
    }
    &-embarcador {
      @include make-md-column(1.4, $gutter: 10px);
      @include make-lg-column(1.4, $gutter: 10px);
    }
    &-municipio {
      @include make-md-column(0.9, $gutter: 10px);
      @include make-lg-column(0.9, $gutter: 10px);
    }
    &-ordem {
      @include make-md-column(1, $gutter: 10px);
      @include make-lg-column(1, $gutter: 10px);
    }
    &-container {
      @include make-md-column(1.2, $gutter: 10px);
      @include make-lg-column(1.2, $gutter: 10px);
    }
    &-inicio,
    &-fim {
      margin-left: 8px;
      @include make-md-column(2.2, $gutter: 10px);
      @include make-lg-column(2.2, $gutter: 10px);
    }
    &-data {
      @include make-md-column(6.5, $gutter: 10px);
      @include make-lg-column(6.5, $gutter: 10px);
    }
    &-hora {
      @include make-md-column(5.5, $gutter: 10px);
      @include make-lg-column(5.5, $gutter: 10px);
    }
  }
}
