.btn {
  border-radius: 4px;
  min-width: 32px;
  min-height: 32px;
  position: relative;

  i {
    position: relative;
    top: 3px;
  }
}

.btn-link {
  font-weight: 500;
  box-shadow: none !important;
}

.btn-primary {
  background-color: $topnav-bg-color;
  border: 0;

  &:hover,
  &:focus {
    background-color: lighten($topnav-bg-color, 1%);
  }
}

.btn-new {
  $btn-new-bg-color: #0480e0;
  margin-left: $md;
  padding: 9px $md $xs !important;
  color: #fff;
  font-size: em(11px);
  padding: $xs $md;
  background-color: $btn-new-bg-color;
  border: 0;
  border-radius: 20px;
  top: -2px;

  &:hover,
  &:focus {
    color: #fff;
    background-color: lighten($btn-new-bg-color, 5%);
  }
}

.btn-m-w-40 {
  min-width: 40px;
}

.btn-cancel {
  border-radius: 20px;
  padding: 10px 20px;
}

.btn-action {
  border-radius: 20px;
  padding: 10px 50px;
  transition: box-shadow .2s ease,border .2s ease;
  -webkit-transition: box-shadow .2s ease,border .2s ease;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .25);
  }
}

.btn-add {
  border-radius: 20px;
  padding: 5px 20px;
  min-height: inherit;
}

.input-group-btn {
  .btn {
    height: 45px;
  }
}

.input-group-date {
  .datepicker-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-search {
    color: $color-primary;
  }
}

.clear-filters {
  margin-top: 15px;
  font-family: $font-2;
  display: inline-block;
  font-size: em(15px);
}

.btn-new-quote {
  background-color: $color-primary;
  border-radius: 4px;
  color: #ffffff;
  padding: $xs $sm;
  font-size: em(12px);
  font-weight: 600;
}
.btn-border-radius{
  border-radius: $lg;
  padding: $xs $sm;
  &.btn-xs{
    font-size: 0.8em;
  }
}