.tp-b {
  &_proposta {
    @include make-xs-column(12, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(3, $gutter: 15px);
    @include make-lg-column(1.8, $gutter: 15px);
  }

  &_container {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(3, $gutter: 15px);
    @include make-lg-column(1.8, $gutter: 15px);
  }

  &_vessel {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(4, $gutter: 15px);
    @include make-lg-column(1.7, $gutter: 15px);
  }

  &_embarque {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(3, $gutter: 15px);
    @include make-lg-column(2, $gutter: 15px);
  }

  &_desembarque {
    @include make-xs-column(6, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(4, $gutter: 15px);
    @include make-lg-column(2, $gutter: 15px);
  }

  &_coleta {
    @include make-xs-column(12, $gutter: 15px);
    @include make-sm-column(4, $gutter: 15px);
    @include make-md-column(3, $gutter: 15px);
    @include make-lg-column(1.5, $gutter: 15px);
  }

  &_actions {
    @include make-xs-column(2, $gutter: 15px);
    @include make-sm-column(2, $gutter: 15px);
    @include make-md-column(1.1, $gutter: 0);
    @include make-lg-column(0.8, $gutter: 0);
  }

  &_coleta {
    text-align: center;

    .btn-coleta {
      width: 100%;
      min-height: inherit;
      border-radius: 20px;
      font-size: em(10px);
      transition: box-shadow .2s ease,border .2s ease;
      padding: $xs $sm;

      &:hover {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .25);
      }
    }
  }

  &_actions {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #eaeff6;
    text-align: center;
    z-index: 1;

    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
      top: inherit;
      bottom: -10px;
    }

    @media (min-width: $tablet-landscape) and (max-width: $desktop) {
      width: 100%;
      top: inherit;
      bottom: -40px;
    }

    .btn {
      min-width: 30px;
      float: left;
      padding: 40px 7px 38px;
      color: $color-primary;
      background-color: transparent;

      @media screen and (max-width: $desktop) {
        padding: $sm;
        float: inherit;
      }

      &:hover {
        color: darken($color-primary, 10%);
      }
    }
  }
}

.coleta-status {
  font-family: $font-1;
  font-size: em(11px);
  text-align: center;
  margin: $sm 0 $xs;

  &.active {
    color: $color-success;
  }

  &.expired {
    color: $color-danger;
    margin-top: 25px;
  }

  &.waiting {
    color: lighten($color-primary, 40%);
    text-overflow: inherit;
    white-space: normal;
    margin-top: 15px;
  }
}

.booking-subtitle {
  margin-bottom: $lg;
  text-transform: uppercase;
  color: #909799;
}

.service-item {
  @include clearfix();
  background-color: #f4f6f9;
  color: #133157;
  font-size: em(12px);
  border-radius: 4px;
  padding: 15px $md $xs;
  margin-bottom: $sm;

  @media screen and (max-width: $tablet-landscape) {
    padding: $sm $sm 0;
  }

  .icon {
    float: left;
    margin-right: $sm;
    font-size: em(30px);
    color: #0773c0;

    @media screen and (max-width: $tablet-landscape) {
      font-size: em(30px);
    }
  }

  .service-title {
    float: left;
    max-width: 65%;
    min-height: 35px;
    font-family: $font-2;
    font-size: em(14px);
    font-weight: 400;
    margin: 0;
    padding-left: $sm;
    border-left: 1px solid #cde2ef;

    @media screen and (max-width: $tablet-landscape) {
      font-size: em(11px);
    }
  }
}

// Agendamento de Coleta
$agendamento-header-bg-color: #7486a5;

.tp-agendamento-header {
  @include clearfix();
  background-color: $agendamento-header-bg-color;
  padding: $sm $sm $xs;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .control-label {
    line-height: normal;
    color: #fff;
    font-size: em(11px);
  }
}

.tp-agendamento-content {
  @include clearfix();
  padding: 15px $sm;
  border-bottom: 1px solid #f5f5f5;
  font-size: em(12px);

  .form-control {
    height: 40px;
    border-color: #cbd5e7;
  }

  .datepicker-input-container {
    width: 75px;

    @media screen and (max-width: $desktop) {
      width: 100%;
    }
  }
}

.tp-container-title {
  font-size: em(11px);
  color: $control-label-color;
  font-weight: 500;
  margin-left: $sm;
  margin-bottom: 0;
}

.tp-container-type {
  font-size: em(12px);
  min-height: 15px;
  margin: 0 0 3px;
}

.tp-container-details {
  margin-left: 35px;

  p {
    margin: 0;
  }

  .icon {
    font-size: em(16px);
    color: #59639f;
    margin-right: 3px;
  }
}

.tp-conteiner {
  float: left;
  margin-left: $sm;

  p {
    margin: 0 0 5px;
    font-family: $font-2;
    font-size: em(13px);
  }

  .icon {
    font-size: em(16px);
    color: #59639f;
    margin-right: 3px;
  }
}

.alert-copy-agendamento {
  @extend .alert-warning;
  font-family: $font-2;
  font-size: em(11px);
  padding: $sm;
  margin: $md;
  text-align: center;

  .btn {
    padding: 2px $sm;
  }
}

.control-date-booking {
  color: lighten($topnav-bg-color, 20%);
  text-transform: inherit;
  font-weight: 500;
}

.tp-b-search-action {
  @extend .search-action;
  display: table-cell !important;
  vertical-align: middle;
  float: inherit !important;
}

.select-group {
  &.booking {
    width: 220px;
    min-width: 200px;
  }
}

// Panel Search Booking
.main-booking {
  .container {
    @media screen and (max-width: $desktop) {
      width: 100%;
    }
  }

  .select-container {
    width: 100%;
  }
}

.main-search {
  &.main-booking{
    .t-b-filter-inicio{
      .datepicker-calendar{
        @media screen and (min-width: 481px) and (max-width: 767px) {
          left: 0 !important;
        }
        @media screen and (min-width: 768px) {
          right: 0 !important;
        }
      }
    }
    .t-b-filter-fim{
      .datepicker-calendar{
        @media screen and (min-width: 768px) {
        right: 0 !important;
      }
    }
    }
  }
  .customer-profile {
    .t-b-filter-number {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(6, $gutter: 20px);
      @include make-lg-column(2.5, $gutter: 20px);
    }

    .t-b-filter-origem {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }

    .t-b-filter-embarque {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }

    .t-b-filter-destino {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }

    .t-b-filter-desembarque {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }

    .t-b-filter-inicio {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
    }

    .t-b-filter-fim {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);
    }
  }

  .t-b-filter-cnpj {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.7, $gutter: 20px);

    .form-control {
      margin-left: 1px !important;
    }
  }

  .t-b-filter-number {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(2.3, $gutter: 20px);

    .form-control {
      margin-left: 1px !important;
    }
  }

  .t-b-filter-origem {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }

  .t-b-filter-embarque {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }

  .t-b-filter-destino {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }

  .t-b-filter-desembarque {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }

  .t-b-filter-inicio {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.7, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }

  .t-b-filter-fim {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.7, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }

  .search-action-booking {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(12, $gutter: 20px);
    @include make-md-column(1, $gutter: 20px);
    @include make-lg-column(0.8, $gutter: 20px);
  }

  .search-action-booking {
    @media screen and (max-width: $tablet-landscape) {
      margin-top: 0;
    }

    .btn-search {
      @media screen and (max-width: $tablet-landscape) {
        width: 98%;
        min-height: inherit;
        margin: $xs;
        border-radius: 4px;
      }

      @media (min-width: $tablet-landscape) and (max-width: $desktop) {
        border-top-right-radius: 0;
      }
    }

    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      position: relative;
      right: -8px;
    }

    @media (min-width: $tablet-landscape) {
      position: absolute;
      border-top-right-radius: 0;
      right: -7px;
    }
  }

  .t-b-filter-cnpj,
  .t-b-filter-number,
  .t-b-filter-origem,
  .t-b-filter-embarque,
  .t-b-filter-destino,
  .t-b-filter-desembarque,
  .t-b-filter-inicio,
  .t-b-filter-fim {
    max-width: inherit !important;
    display: inline-block !important;
    min-height: 60px !important;

    .select-group {
      min-height: 60px !important;
    }

    .datepicker-container,
    .datepicker-input-container,
    .datepicker-input {
      width: 85px !important;
    }

    @media screen and (max-width: $desktop) {
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
  }

  // .t-b-filter-cnpj {
  //   @media (max-width: $tablet) {
  //     border-right: 0;
  //   }
  // }

  .t-b-filter-embarque {
    @media (min-width: $tablet) and (max-width: $desktop) {
      border-right: 0;
    }
  }

  .t-b-filter-fim {
    border-right: 0;
  }

  .t-b-filter-number {
    .form-control {
      height: 55px;
    }
  }

  .t-b-filter-origem,
  .t-b-filter-destino,
  .t-b-filter-inicio {
    @media (max-width: $tablet) {
      border-right: 1px solid #e4e4e4 !important;
    }
  }
}

.customer-profile {
  .t-b-filter-cnpj {
    display: none !important;
  }
}

.select-group .datepicker-container[_ngcontent-c6] .datepicker-input-container[_ngcontent-c6] .datepicker-input[_ngcontent-c6] {
  width: 96% !important;
}

// Icones Booking ------

.radio-service-item,
.service-item,
.check-service-item {
  .icon:before,
  .icon-not-found {
    content: "\e013";
  }

  // Carreta Aberta
  .icon-34:before,
  .icon-47:before,
  .icon-21:before {
    content: "\e00c";
  }

  // Carreta Rebaixada
  .icon-122:before {
    content: "\e00e";
  }

  // Carreta Bau
  // Carreta Sider
  .icon-48:before,
  .icon-143:before {
    content: "\e00f";
  }

  // Equipe Cliente
  .icon-133:before {
    content: "\40";
  }

  // Ova Terminal
  .icon-4:before,
  .icon-60:before,
  .icon-61:before,
  .icon-62:before,
  .icon-63:before {
    content: "\3f";
  }

  // Desova Terminal
  .icon-5:before,
  .icon-40:before,
  .icon-41:before,
  .icon-42:before,
  .icon-43:before,
  .icon-147:before {
    content: "\e00d";
  }

  // Genset
  .icon-76:before,
  .icon-141:before {
    content: "\2c";
  }

  // Carga Excesso
  .icon-52:before {
    content: "\32";
  }

  // Custo Financeiro
  .icon-91:before,
  .icon-118:before {
    content: "\e008";
  }

  // Ad Valorem
  .icon-25:before {
    content: "\e015";
  }

  // Entrega Especial
  .icon-73:before {
    content: "\5b";
  }

  // Handling
  .icon-9:before,
  .icon-73:before,
  .icon-127:before,
  .icon-128:before,
  .icon-126:before {
    content: "\e010";
  }

  .icon-carga_perigosa:before {
    content: "\56";
  }

  .icon-carga_seca:before {
    content: "\2a";
  }

  .icon-escolta:before {
    content: "\e011";
  }

  .icon-lavagem:before,
  .icon-quimica:before {
    content: "\e012";
  }

  .icon-paletizacao:before {
    content: "\e014";
  }

  .icon-conferente:before {
    content: "\61";
  }
}

.n-booking a {
  color: $topnav-bg-color;

  &:hover {
    color: darken($topnav-bg-color, 10%);
  }
}


.panel-body-coleta {
  padding: $md 0;
  margin-top: $sm;
}
