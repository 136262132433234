$search-container-bg-color: #fff;
.main-usuario {
    &+.main-content{
        td,th{
            padding: 20px 10px;
        }
    }
    .select-container {
        display: table;
        width: 100%;

        table-layout: fixed;
        margin: 0 auto;
        border-radius: 8px;
        background-color: $search-container-bg-color;
        box-shadow: 0px 2px 7px 0px rgba(43, 66, 92, 0.5);
        position: relative;
        .dropdown-inline {
          width: 100%;
        }
        .dropdown-menu .empty {
          display: none;
        }
      }
      .t-b-filter-cliente {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(4, $gutter: 20px);
        @include make-md-column(6, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
      }

      .t-b-filter-email {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(4, $gutter: 20px);
        @include make-md-column(3, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
      }

      .t-b-filter-nome-empresa {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(4, $gutter: 20px);
        @include make-md-column(3, $gutter: 20px);
        @include make-lg-column(1.7, $gutter: 20px);
      }

      .t-b-filter-perfil {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(3, $gutter: 20px);
        @include make-md-column(3, $gutter: 20px);
        @include make-lg-column(1.7, $gutter: 20px);
      }

      .t-b-filter-status {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(2, $gutter: 20px);
        @include make-md-column(3, $gutter: 20px);
        @include make-lg-column(1.4, $gutter: 20px);
      }

      .t-b-filter-cnpj {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(2.5, $gutter: 20px);
        @include make-md-column(3, $gutter: 20px);
        @include make-lg-column(1, $gutter: 20px);
      }
      .t-b-filter-data {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(4.5, $gutter: 20px);
        @include make-md-column(3, $gutter: 20px);
        @include make-lg-column(2.7, $gutter: 20px);
      }




    .search-action-usuarios {
      @media screen and (max-width: $tablet-landscape) {
        margin-top: 0;
      }

      .btn-search {
        @media screen and (max-width: $tablet-landscape) {
          width: 98%;
          min-height: inherit;
          margin: $xs;
          border-radius: 4px;
        }

        @media (min-width: $tablet-landscape) and (max-width: $desktop) {
          border-top-right-radius: 0;
        }
      }

      @media (min-width: $tablet) and (max-width: $tablet-landscape) {
        position: relative;
        right: -8px;
      }

      @media (min-width: $tablet-landscape) {
        position: absolute;
        border-top-right-radius: 0;
        right: -7px;
      }
    }
    .select-group {
        position: relative;


        display: table-cell;
        vertical-align: middle;
        border-right: 1px solid lighten($search-label-color, 35%);
        .form-control-loading {
          right: 2% !important;
          top: 35% !important;
          background-color: #ffffff;
          height: 30px;
          z-index: 999999;
        }
        &.sm {
          width: 130px;
          max-width: 130px;
        }
        &.lg {
          width: 400px;
          max-width: 400px;
        }
        @media screen and (max-width: $mobile) {
          display: inline-block;
          
          max-width: 100%;
          border-right: 0;
          border-bottom: 1px solid lighten($search-label-color, 35%);
        }
        @media (min-width: $mobile) and (max-width: $tablet) {
          display: inline-block;
        
         
          border-bottom: 1px solid lighten($search-label-color, 35%);
        }
        .control-label {
          font-family: $font-2;
          font-size: em(11px);
          font-weight: 500;
          color: $search-label-color;
          display: block;
          margin: $xs 0 0;
        }
        .form-control {
          font-family: $font-2;
          font-size: em(11px);
          height: 58px;
          font-weight: bold;
          color: $search-content-color;
          background-color: transparent;
          border: 0;
          outline: none !important;
          box-shadow: inherit;
          padding: 0;
        }
      }
    .t-b-filter-cliente,
    .t-b-filter-email,
    .t-b-filter-nome-empresa,
    .t-b-filter-perfil,
    .t-b-filter-status,
    .t-b-filter-cnpj{
      max-width: inherit !important;
      display: inline-block !important;
      min-height: 58px !important;

      .select-group {
        min-height: 58px !important;
      }

      .datepicker-container,
      .datepicker-input-container,
      .datepicker-input {
        width: 85px !important;
      }

      @media screen and (max-width: $desktop) {
        border-bottom: 1px solid lighten($search-label-color, 35%);
      }
    }

    .bootstrap-select,
    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 100%;
    }
    .select-group {
      .btn-default {
        @include text-overflow();
        font-family: $font-2;
        font-size: em(11px);
        font-weight: bold;
        color: $search-content-color;
        background-color: transparent;
        border: 0;
        outline: none !important;
        box-shadow: inherit;
        padding: 0; // @media screen and (max-width: $tablet-landscape) {
        //   font-size: em(11px);
        // }
        &:hover,
        &:focus {
          color: lighten($search-content-color, 15%);
          background-color: transparent;
          border: 0;
          outline: none !important;
          box-shadow: inherit;
        }
      }
      .dropdown-menu>.selected>a {
        font-weight: bold;
        .check-mark {
          font-family: alianca-icons!important;
          font-style: normal!important;
          font-weight: 400!important;
          position: relative;
          top: -1px;
          &:before {
            content: "\4a";
          }
        }
      }
      .dropdown-menu {
        .form-control {
          border: 1px solid lighten($search-label-color, 20%);
        }
      }
      .dropdown-menu>li>a {
        font-family: $font-2;
        font-size: em(11px);
        padding: $xs $sm;
      }
      .filter-option {
        @include text-overflow();
        width: 95% !important;
      }
      .dropdown-toggle .caret {
        right: 0;
      }
    }


    .search-action {
        display: inline-block;
        float: right;
        @media screen and (max-width: $tablet) {
          margin-top: $sm;
          width: 100%;
          display: block;
          float: inherit;
        }
      }
      .btn-search {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: $search-btn-color;
        color: #fff;
        font-size: em(20px);
        min-width: 60px;
        @media screen and (max-width: $mobile) {
          width: 94%;
          margin: 0 0 $sm $sm;
          border-radius: 4px;
        }
        @media (min-width: $mobile) and (max-width: $tablet) {
          width: 95%;
          margin: 0 0 $sm 15px;
          border-radius: 4px;
        }
        &:hover {
          background-color: darken($search-btn-color, 7%);
          transition: all 0.3s !important;
        }
      }
      .select-group,
      .btn-search {
        min-height:58px;
        @media screen and (max-width: $mobile) {
         
        }
        @media (min-width: $mobile) and (max-width: $tablet) {
        
        }
      }
      .select-group {
        @media screen and (max-width: $mobile) {
          min-height: 62px;
        }
      }





  }
