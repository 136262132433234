.main-admin {
	width: 100%;
	padding: $md;
	min-height: 100px;
	background-color: $search-bg-color;
	background-position: top center;
	background-size: cover;
	background-image: url(#{$img-path}/bg-admin.jpg);
	margin-bottom: $lg;

	.page-title{
		@media screen and (max-width: 480px) {
			font-size: 1.8em;
			margin-top: $sm;
		}
	}

	.bhoechie-tab-container {
		.control-label {
			text-transform: inherit;
		}
	}
}
