.result-cargas-wrapper {
  $title-color: #838591;
  .product-cargas {
    $title-color: #838591;
    @include clearfix();
    position: relative;
    padding: 15px 0 2px $sm;
    min-height: 114px;
    margin-bottom: $sm;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(98, 98, 98, 0.5);
    @media screen and (max-width: $tablet-landscape) {
      padding: 15px $sm 40px;
    }
    .icon-step {
      min-height: 114px;
      .icon:before {
        font-family: "alianca-icons" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.confirmadas {
      .icon-step {
        background-color: #57a5a4;
        .icon {
          @extend .icon-check-circle;
        }
      }
    }
    &.coletadas {
      .icon-step {
        background-color: #981e1e;
        .icon {
          @extend .icon-package-delivery;
        }
      }
    }
    &.depositadas {
      .icon-step {
        background-color: #0061a6;
        .icon {
          @extend .icon-transport;
        }
      }
    }
    &.navegando {
      .icon-step {
        background-color: #002b5c;
        .icon {
          @extend .icon-navegando;
        }
      }
    }
    &.desembarcadas {
      .icon-step {
        background-color: #8e6cd5;
        .icon {
          @extend .icon-desembarcadas;
        }
      }
    }
    &.em-entrega {
      .icon-step {
        background-color: #ff7e00;
        .icon {
          @extend .icon-truck-movement;
        }
      }
    }
    &.realizadas {
      .icon-step {
        background-color: #2ea94b;
        .icon {
          @extend .icon-check;
        }
      }
    }
    &.retirada-cheio {
      .icon-step {
        background-color: #002b5c;
        .icon {
          @extend .icon-truck-3;
        }
      }
    }
    &.devolucao-vazio {
      .icon-step {
        background-color: #981e1e;
        .icon {
          background:url(#{$img-path}/icon/retirada-vazio-branco.svg);
          background-size: cover;
          background-repeat: no-repeat;
          display: block;
          width: 24px;
          height: 20px;
          margin: 40px 0px;
        }
      }
    }
    &_item {
      @media screen and (max-width: $mobile) {
        margin-bottom: $sm;
      }
      .control-label {
        font-family: $font-2;
        font-size: em(11px);
        font-weight: 600;
        text-transform: none;
        color: $title-color;
      }
      .icon {
        position: relative;
        top: 5px;
        color: lighten($title-color, 10%);
      }
    }
    &_details {
      font-family: $font-2;
      font-size: em(12px);
    }
    .data,
    .local {
      @include clearfix();
      font-size: em(15px);
      color: $color-primary;
      margin: 0 0 5px;
    }
    .data {
      font-weight: bold;
    }
    .n-proposta {
      @include text-overflow();
      font-size: em(14px);
      font-weight: bold;
      color: $color-primary;
      margin: 0;
      @media screen and (max-width: $tablet-landscape) {
        font-size: em(12px);
      }
    }
    .conteiner {
      font-size: em(13px);
    }
    .deadline {
      color: $color-primary;
    }
    .container-details {
      margin-left: 35px;
      p {
        margin: 0;
      }
      .icon {
        font-size: em(16px);
        color: #59639f;
        margin-right: 3px;
      }
    }
    .agendar p{
      opacity: 1;
    }
    p {
      color: #616161;
      font-size: 10px;
      opacity: 0.85;
      @include text-overflow();
      .sub-label {
        color: #616161;
        font-weight: 700 !important;
        font-size: 11px;
      }
    }
    &.confirmadas {
      .agendar p{
        color: #57a5a4;
        font-size: 10px;
      }
    }

    &.coletadas {
      .agendar p{
        color: #981e1e;
        font-size: 10px;
      }
    }

    &.depositadas {
      .agendar p{
        color: #0061a6;
        font-size: 10px;
      }
    }

    &.navegando {
      .agendar p{
        color: #002b5c;
        font-size: 10px;
      }
    }

    &.desembarcadas {
      .agendar p{
        color: #8e6cd5;
        font-size: 10px;
      }
    }

    &.em-entrega {
      .agendar p{
        color: #ff7e00;
        font-size: 10px;
      }
    }

    &.realizadas {
      .agendar p{
        color: #2ea94b;
        font-size: 10px;
      }
    }

    &.devolucao-vazio {
      .agendar p{
        color: #981e1e;
        font-size: 10px;
      }
    }

    &.retirada-cheio {
      .agendar p{
        color: #002b5c;
        font-size: 10px;
      }
    }

  }
  .icon {
    position: relative;
    top: 5px;
    color: lighten($title-color, 10%);
  }
  .icon-step {
    @include make-xs-column(1.2, $gutter: 15px);
    @include make-sm-column(.7, $gutter: 15px);
    @include make-md-column(.4, $gutter: 15px);
    @include make-lg-column(.4, $gutter: 15px);
    position: absolute;
    top: 0;
    left: 0; // background-color: $azul;
    text-align: center;
    vertical-align: middle;
    i {
      font-size: 1.5em;
      color: #fff;
    }
    @media screen and (max-width: $tablet) {
      line-height: 315px;
    }
    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      line-height: 230px;
    }
    @media screen and (min-width: $tablet-landscape) {
      line-height: 95px;
    }
  }
  .booking {
    @include make-xs-column(12, $gutter: 15px);
    @include make-sm-column(6, $gutter: 15px);
    @include make-md-column(4, $gutter: 15px);
    @include make-lg-column(4, $gutter: 15px);
    label,h3,p{
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: 0;
    }
    h3{
      margin: 0 $xs !important;
    }
    p {
      font-size: 13px;
      vertical-align: top;
      color: #323843;
      opacity: 1;
      letter-spacing: 0;
      i {}
      span {
        display: inline-block;
        line-height: $sm;
        vertical-align: bottom;
        font-weight: 300;

      }
    }
  }
  .container{
    @include make-xs-column(12, $gutter: 15px);
    @include make-sm-column(5, $gutter: 15px);
    @include make-md-column(4, $gutter: 15px);
    @include make-lg-column(4, $gutter: 15px);
    @media screen and (max-width: $tablet) {
      margin: 15px 0;
    }
    label,h3,p,.tp-container-details{
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: 0;

      opacity: 1;
    }
    .tp-container-title{
      margin-bottom: $xs;
    }
    .tp-container-details {
      margin-left: $xs;
      margin-bottom: $xs;
      p{
         font-size: 0.9em;
         letter-spacing: 0px;
        }

        i{
        display: inline-block;
        vertical-align: middle;

        color: #5c639c;
        font-size: em(16px);
      }
      .tp-container-type{
       margin-right: 5px;
       color: #323843;
      }
    }
  }
  .check-ordem {
    @include make-xs-column(12, $gutter: 5px);
    @include make-sm-column(5, $gutter: 5px);
    @include make-md-column(0.4, $gutter: 5px);
    @include make-lg-column(0.4, $gutter: 5px);
    p {
      i {}
      span {
        display: inline-block;
        line-height: $sm;
        vertical-align: bottom;
        font-weight: 300;
      }
    }
  }
  $xs-res: 5;
  $sm-res: 4;
  $md-res: 1.8;
  $lg-res: 2;
  .agendar,
  .navio_viagem,
  .booking,
  .destinatario,
  .container {
    @media screen and (max-width: $tablet) {
      margin-left: 10%;
    }
  }
  .booking,
  .embarcador,
  .agendar,
  .previsao_chegada {
    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      margin-left: 8%;
    }
  }
  .booking,
  .check-ordem,
  .navio_viagem {
    @media screen and (min-width: $tablet-landscape) {
      margin-left: 4%;
    }
  } // .previsao_chegada {
  // 	@media screen and (max-width: $tablet) {
  // 		margin-left: 7%;
  // 	}
  // }
  .navio_viagem {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column($sm-res, $gutter: 15px);
    @include make-md-column(2.4, $gutter: 15px);
    @include make-lg-column(2.4, $gutter: 15px);
    @media screen and (min-width: $tablet) and (max-width: $tablet-landscape) {
      margin-left: 55px !important;
    }
  }
  .ordem-servico {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column($sm-res, $gutter: 15px);
    @include make-md-column(1.2, $gutter: 15px);
    @include make-lg-column(1.2, $gutter: 15px);
  }
  .tipo-servico {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column($sm-res, $gutter: 15px);
    @include make-md-column(1.2, $gutter: 15px);
    @include make-lg-column(1.2, $gutter: 15px);
  }
  .fatura {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column($sm-res, $gutter: 15px);
    @include make-md-column(1.2, $gutter: 15px);
    @include make-lg-column(1.2, $gutter: 15px);
  }
  .data-programacao {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column($sm-res, $gutter: 15px);
    @include make-md-column(1.5, $gutter: 15px);
    @include make-lg-column(1.5, $gutter: 15px);
  }
  .embarcador {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column(3.5, $gutter: 15px);
    @include make-md-column(2.2, $gutter: 15px);
    @include make-lg-column(2.2, $gutter: 15px);
  }
  .embarque {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column(3.5, $gutter: 15px);
    @include make-md-column($md-res, $gutter: 15px);
    @include make-lg-column($lg-res, $gutter: 15px);
  }
  .destinatario {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column(3.5, $gutter: 15px);
    @include make-md-column($md-res, $gutter: 15px);
    @include make-lg-column($lg-res, $gutter: 15px);
  }
  .previsao_chegada {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column(3, $gutter: 15px);
    @include make-md-column($md-res, $gutter: 15px);
    @include make-lg-column($lg-res, $gutter: 15px);
  }
  .agendar {
    @include make-xs-column(12, $gutter: 0);
    @include make-sm-column(12, $gutter: 0);
    @include make-md-column(2.2, $gutter: 0);
    @include make-lg-column(2.2, $gutter: 0);
    text-align: center;
    @media screen and (max-width: $tablet-landscape) {
      text-align: left;
    }
    .btn-agendar {
      border-radius: $lg;
      font-weight: 300;
      font-size: 0.7em;
      padding: 0 $md;


      @media screen and (max-width: $tablet-landscape) {
        width: 90%;
      }
      i{
        color: #fff;
        font-size: 2em;
      }
    }
    .btn-consultar {
      border-radius: $lg;
      text-align: center;
      line-height: 1.5em;
      font-weight: 300;
      font-size: 0.75em;
      padding: 5px 12px;
      min-height: 20px;
      margin-bottom: $xs;

      @media screen and (max-width: $tablet-landscape) {
        width: 90%;
      }
    }
    p{
      margin: 0;
    }
    .quotation-status {
      opacity: 1;
    }
  }
  .actions {
    @include make-xs-column($xs-res, $gutter: 15px);
    @include make-sm-column($sm-res, $gutter: 15px);
    @include make-md-column(0.6, $gutter: 15px);
    @include make-lg-column(0.6, $gutter: 15px);
    position: absolute;
    top: 0;
    right: 0;
    background-color: #eaeff6;
    text-align: center;
    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
      top: inherit;
      bottom: -10px;
    }
    .btn {
      padding: 47px 8px 45px;
      color: $color-primary;
      background-color: transparent;
      @media screen and (max-width: $tablet-landscape) {
        padding: $sm;
        width: 100%;
      }
      &:hover {
        color: darken($color-primary, 10%);
      }
    }
  }
  .control-label {
    font-family: $font-2;
    font-size: em(11px);
    font-weight: 600;
    text-transform: none;
    color: $title-color;
  }
}

.product-cargas-collapse {
  @include clearfix();
  position: relative;
  margin-top: -10px;
  margin-bottom: $sm;
  background-color: #f7f7f7;
  box-shadow: 0 1px 1px 0 rgba(98, 98, 98, .5);
  @media screen and (max-width: $tablet-landscape) {
    margin-top: -5px;
  }
  .panel-body {
    padding: $md $md $sm;
    font-family: $font-2;
  }
}
.btVerMapa{
  float: right;
  background: $color-primary;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 4px 3px;
  img{
    max-width: 100%;
    height: auto;
  }
}
