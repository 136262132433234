//------------------------------------------------------------//
//------ INITIAL HOME LOADER
//------------------------------------------------------------//

.home-loader {
  @include gradient-horizontal(rgba($topnav-bg-color, 1), rgba($topnav-bg-color, .74), -45deg);
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: $topnav-bg-color;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;

  $width: 3rem;
  $bar-color: #fff;

  .loading-bar {
    @include centre();
    height: $width;
    width: $width;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bar {
      flex: 0 0 20%;
      height: 30%;
      animation: pulse 1s ease infinite;
      background-color: rgba($bar-color, .74);
      animation-delay: 0;
      border-radius: 12px;

      &:nth-child(2) {
        animation-delay: .3s;
      }

      &:nth-child(3) {
        animation-delay: .6s;
      }
    }
  }

  @keyframes pulse {
    0% {
      height: 30%;
    }
    50% {
      height: 100%;
    }
    100% {
      height: 30%;
    }
  }
}

.product-cargas-collapse {
  .page-loader {
    background-color: #f7f7f7;
  }
}

.page-loader {
  width: 100%;
  height: 100%;
  min-height: 420px;
  border-radius: 6px;
  z-index: 3;
  position: relative;
  // position: absolute;
  background-color: #fff;
  top: -15px;
  right: 0;
  left: 0;
  bottom: 0;

  $width: 3rem;

  .loading-bar {
    @include centre();
    height: $width;
    width: $width;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bar {
      flex: 0 0 20%;
      height: 30%;
      /*animation: pulse 1s ease infinite;*/
      animation-name: pulse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      background-color: rgba($topnav-bg-color, .54);
      animation-delay: 0;
      border-radius: 12px;

      &:nth-child(2) {
        animation-delay: .3s;
      }

      &:nth-child(3) {
        animation-delay: .6s;
      }
    }
  }

  @keyframes pulse {
    0% {
      height: 30%;
    }
    50% {
      height: 100%;
    }
    100% {
      height: 30%;
    }
  }
}

.co2-loader {
  width: 100%;
  height: 100%;
  position: relative;
  // position: absolute;
  right: 0;
  left: 0;
  margin-bottom: 15%;
  margin-top: 5%;
  bottom: 0;
  left: -5%;

  $width: 3rem;

  .loading-bar {
    @include centre();
    height: $width;
    width: $width;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bar {
      flex: 0 0 20%;
      height: 30%;
      /*animation: pulse 1s ease infinite;*/
      animation-name: pulse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      background-color: rgba($topnav-bg-color, .54);
      animation-delay: 0;
      border-radius: 12px;

      &:nth-child(2) {
        animation-delay: .3s;
      }

      &:nth-child(3) {
        animation-delay: .6s;
      }
    }
  }

  @keyframes pulse {
    0% {
      height: 30%;
    }
    50% {
      height: 100%;
    }
    100% {
      height: 30%;
    }
  }
}

@keyframes form-control-loading {
  0% {
    transform-origin: 100% 100%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 100% 100%;
    transform: rotate(360deg);
  }
}

.form-control-loading {
  width: 24px;
  height: 24px;
  margin: 5px 5px 0 0;
  position: absolute;
  top: 10%;
  right: 5%;
}

.form-control-loading:before {
  position: absolute;
  display: block;
  content: "";
  z-index: 3;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FFF;
}

.btn-group.dropdown {
  .form-control-loading {
    top: 30%;
  }

  .form-control-loading:before {
    background-color: #eaeff6;
  }
}

.form-control-loading:after {
  position: absolute;
  display: block;
  content: "";
  z-index: 2;
  width: 12px;
  height: 12px;
  border-radius: 200px 0 0;
  background: linear-gradient(45deg, transparent 0, #459ad7 50%, #459ad7 100%);
  animation-name: form-control-loading;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
