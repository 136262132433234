.label-switch {
  $action-color: $color-success !default;
  $base-background-color: white !default;
  $switch-width: 52px;
  $switch-padding: 2px;
  $switch-height: 28px;
  $switch-radius: $switch-height;
  $knob-size: $switch-height - ($switch-padding * 2);
  $knob-radius: $switch-height - ($switch-padding * 2);
  $knob-width: $knob-size;
  $switch-background: $base-background-color;
  $switch-border-background: darken($base-background-color, 9%);
  $switch-shadow: 0 2px 5px transparentize(black, 0.6);

  border-radius: $switch-radius;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  position: relative;
  width: $switch-width;
  margin: $xs 0 0;

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      background: $switch-border-background;
      border: 0;
      border-radius: $switch-radius;
      cursor: pointer;
      height: $switch-height;
      margin: 0;
      padding: 0;
      position: relative;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      width: $switch-width;
      z-index: 0;

      &::before {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 2px;
        background: $base-background-color;
        border-radius: 32px;
        content: "";
        height: $knob-size;
        transform: scale(1);
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        width: 48px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 2px;
        height: $knob-size;
        width: $knob-size;
        background: $base-background-color;
        border-radius: $knob-size;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        content: "";
        transition: all 0.3s ease;
        z-index: 2;
      }
    }

    &:checked {
      + .checkbox {
        background: $action-color;

        &::before {
          transform: scale(0);
        }

        &::after {
          left: $switch-width - $knob-width - ($switch-padding);
        }
      }
    }
  }
}

.label-switch-sm {
  $action-color: $color-success !default;
  $base-background-color: white !default;
  $switch-width: 42px;
  $switch-padding: 2px;
  $switch-height: 20px;
  $switch-radius: $switch-height;
  $knob-size: $switch-height - ($switch-padding * 2);
  $knob-radius: $switch-height - ($switch-padding * 2);
  $knob-width: $knob-size;
  $switch-background: $base-background-color;
  $switch-border-background: darken($base-background-color, 9%);
  $switch-shadow: 0 2px 5px transparentize(black, 0.6);

  border-radius: $switch-radius;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  position: relative;
  width: $switch-width;
  margin: $xs 0 0;

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      background: $switch-border-background;
      border: 0;
      border-radius: $switch-radius;
      cursor: pointer;
      height: $switch-height;
      margin: 0;
      padding: 0;
      position: relative;
      transition: all 0.3s ease;
      width: $switch-width;
      z-index: 0;

      &::before {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 2px;
        background: $base-background-color;
        border-radius: 32px;
        content: "";
        height: $knob-size;
        transform: scale(1);
        transition: all 0.3s ease;
        width: 48px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 2px;
        height: $knob-size;
        width: $knob-size;
        background: $base-background-color;
        border-radius: $knob-size;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        content: "";
        transition: all 0.3s ease;
        z-index: 2;
      }
    }

    &:checked {
      + .checkbox {
        background: $action-color;

        &::before {
          transform: scale(0);
        }

        &::after {
          left: $switch-width - $knob-width - ($switch-padding);
        }
      }
    }
  }
}

.custom-radio {
  input {
    // display: none;
    position: absolute;
    left: -9999px;
  }

  label {
    line-height: normal;
  }

  input + label {
    color: $text-color;
    font-weight: normal;
    font-size: em(13px);

    cursor: pointer;
    padding-left: 30px;
    position: relative;
  }

  input:checked+label {
    color: $color-primary;
    font-weight: 600;
  }

  label:before {
    content: "";
    top: -2px;
    left: 0;
    width: 22px;
    height: 22px;
    position: absolute;
    background: $color-primary;
    border-radius: 100%;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 18px #eaedee;

    // &:focus {
    //   outline-offset: -2px;
    //   border-color: #66afe9;
    //   outline: 0;
    //   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    // }
  }

  input:focus + label:before {
    border: none;
    box-shadow: inset 0 0 0 3px $color-primary;
    background-color: #fff;
  }

  input:checked + label:before {
    border: 1px solid $color-primary;
    box-shadow: inset 0 0 0 4px #fff;
    background-color: #003f8c;
  }
}
