






.detalhes-rastreamento {
  font-family: $font-2;
  position: relative;
  h4 {
    font-size: em(12px);
    font-weight: 600;
    text-transform: uppercase;
    color: $filter-title-color;
    border-bottom: 1px solid #e0e5ef;
    padding-top: $md;
    padding-bottom: $sm;
  }
  .timeline {
    @include clearfix();
    padding-bottom: $lg;
    >li{
        &:first-child ul li:first-child {
        .line {
          width: 50%;
          left: 50%;
          @media screen and (max-width: $tablet-landscape) {
            width: 2px !important;
            left: 0;
            height: 100%;
          }
        }
      }
      &:last-child {
        .line {
          width: 50%;
          right: 50%;
          @media screen and (max-width: $tablet-landscape) {
            display: none;
          }
        }
      }
      @include make-xs-column(12, $gutter: 0px);
      @include make-sm-column(12, $gutter: 0px);
      @include make-md-column(1.5, $gutter: 0px);
      text-align: center;
      position: relative;
      @media screen and (max-width: $tablet-landscape) {
        text-align: left;
      }
      @media screen and (min-width: 1200px) {
        width: 11%;
      }

       &.item-transbordo{
        display: none;
        @media screen and (min-width: 1024px) {
          display: block;
        }
        overflow: hidden;
        @media screen and (min-width: 768px) {
          height: 250px;
        }
        .slick-track{
          min-width: 247px;
        }
        @media screen and (min-width: 1200px) {
            width: 22% !important;

          }
        .slick-arrow{
           top: 170px;
          &::before{
            color:#797979;
          }
          &.slick-prev{
            left: 40%;
          }
          &.slick-next{
            right: 40%;
          }
        }
        .slick-slide{
          position: relative;

        }
        &-mobile{
          display: none;
          @media screen and (max-width: 1024px) {
            display: block;
          }
        }
      }


    }
    li ul {
      .line {
        height: 2px;
        background: #90a4b2;
        position: absolute;
        width: 100%;
        top: 28px;
        @media screen and (max-width: $tablet-landscape) {
          width: 2px;
          height: 100%;
        }
        &.ok{

            background: #18b73d;

           }
        &.ok-last{
                    @media screen and (min-width: $tablet-landscape) {
                            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#18b73d+48,7db909+50,90a4b2+50 */
                            background: #18b73d; /* Old browsers */
                            background: -moz-linear-gradient(left, #18b73d 48%, #7db909 50%, #90a4b2 50%) !important; /* FF3.6-15 */
                            background: -webkit-linear-gradient(left, #18b73d 48%,#7db909 50%,#90a4b2 50%) !important; /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to right, #18b73d 48%,#7db909 50%,#90a4b2 50%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b73d', endColorstr='#90a4b2',GradientType=1 ) !important; /* IE6-9 */
                    }
                    @media screen and (max-width: $tablet-landscape) {
                      background: #90a4b2;
                    }
          }
      }
      .circle {
        width: 16px;
        height: 16px;
        background: #90a4b2;
        position: absolute;
        left: 50%;
        margin-left: -$sm;
        border-radius: $md;
        top: $md;
        z-index: 100;
        @media screen and (max-width: $tablet-landscape) {
          left: 3px;
        }
      }
      .circle-success {
        width: 16px;
        height: 16px;
        background: #18b73d;
        position: absolute;
        left: 50%;
        margin-left: -$sm;
        border-radius: $md;
        top: $md;
        z-index: 100;
        @media screen and (max-width: $tablet-landscape) {
          left: 3px;
        }
      }

      .atrasado {
        color: red;
      }

      .realizado {
        color: #18b73d;
      }
      .title {
        font-size: em(10px);
        text-transform: uppercase;
        font-weight: 600;
        color: $color-success;
        margin-top: 50px;
        @media screen and (max-width: $tablet-landscape) {
          margin-top: $md;
        }
      }
      .local {
        font-size: em(11px);
        color: $color-primary;
        font-weight: 500;
      }
      p {
        font-size: em(11px);
        margin-bottom: 0;
      }
      .title,
      .local,
      p {
        @media screen and (max-width: $tablet-landscape) {
          margin-left: $md;
        }
      }
    }
  }

}

// Panel Tracking - Filtros
.main-rastreamento-cargas {
  .container {
    @media screen and (max-width: $desktop) {
      width: 100%;
    }
  }
  .controle-inputs{
    width: 100%;
    @media screen and (min-width: 769px)  {
    width: calc(100% - 60px);
    }
    float: left;
  }
  .select-container {
    width: 100%;
    &.has-period{
      @media (min-width: 1355px) {
        width: 90%;
      }
    }
  }
  @media screen and (min-width: 480px)  and (max-width: 767px){
    .datepicker-calendar{
      left: 0 !important;
    }
  }
  @media screen and (min-width: 768px)  {
  .datepicker-calendar{
    right: 0 !important;
  }
}
}

.main-search {
 /*.select-group.t-t-filter-navio-viagem {
    @media screen and (max-width: $tablet-landscape) {
      width:100% !important;
      max-width: 100% !important;
    }
  }
  */
  .disabled{
    background:#e0e0e0;
    .form-control{
      opacity: 0.1 !important;
    }
  }
  .t-t-filter-fim{
    display: none;
  }
  .has-period {
    &.customer-profile {
      .t-t-filter-cnpj {
        display: none !important;
      }
      .t-t-filter-number {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(6, $gutter: 20px);
        @include make-md-column(2.3, $gutter: 20px);
        @include make-lg-column(1.5, $gutter: 20px);
      }
      .t-t-filter-navio-viagem {
        @include make-xs-column(12, $gutter: 20px);
        @include make-sm-column(3, $gutter: 20px);
        @include make-md-column(4, $gutter: 20px);
        @include make-lg-column(1, $gutter: 20px);
        .form-control {
          margin-left: 1px !important;
          height: 55px;
        }
      }
      .t-t-filter-periodo {
        @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(3, $gutter: 20px);
        @include make-md-column(1.4, $gutter: 20px);
        @include make-lg-column(1.4, $gutter: 20px);
        border-right: 1px solid #e4e4e4 !important;
      }
    }

    .t-t-filter-cnpj {
     /* @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(2.7, $gutter: 20px);
     @include make-md-column(4, $gutter: 5px);

      @include make-lg-column(1.2, $gutter: 5px);
      */
      @media (min-width: 1355px) {
        padding: 0 5px;
        width:20%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:20%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:20%;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
          width: 100%;
          padding: 0 20px;
      }
      border-right: 1px solid #e4e4e4 !important;
      .form-control {
        margin-left: 1px !important;
      }
    }
    .t-t-filter-number {
      @media (min-width: 1355px) {
        padding: 0 5px;
        width:20%;
        .form-control{
          padding-top: 20px;
        }
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:20%;
        .form-control{
          padding-top: 20px;
        }
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:20%;
        .main-search .has-period .t-t-filter-number .form-control {
          padding-top: 14px;
      }
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
          width: 100%;
          padding:0 20px;
      }
     // @include make-xs-column(12, $gutter: 20px);
      //@include make-sm-column(3, $gutter: 20px);
      //@include make-md-column(4, $gutter: $xs);
      //@include make-lg-column(2, $gutter: $xs);

    }
    .t-t-filter-navio-viagem {
      @media (min-width: 1355px) {
        padding: 0 5px;
        width:20%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:20%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:20%;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
          width: 100%;
          padding: 0 20px;
      }

     // @include make-xs-column(12, $gutter: 20px);
      //@include make-sm-column(3, $gutter: 20px);
      //@include make-md-column(4, $gutter: 20px);
      //@include make-lg-column(1.1, $gutter: 20px);
      .form-control {
        margin-left: 1px !important;
        height: 55px;
      }
    }
    .t-t-filter-origem {
     /* @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.9, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);*/

      @media (min-width: 1355px) {
        padding: 0 5px;
        width:20%;
        border-right: none;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:20%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:20%;
        border-right: none;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
          width: 100%;
          padding: 0 20px;
      }
    }
    .t-t-filter-embarque {
      /*  @include make-xs-column(6, $gutter: 20px);
        @include make-sm-column(3, $gutter: 20px);
        @include make-md-column(1.9, $gutter: 20px);
        @include make-lg-column(1.3, $gutter: 20px);*/
        @media (min-width: 1355px) {
          padding: 0 5px;
          width:16.5%;
        }

        /* Landscape tablets and medium desktops */
        @media (min-width: 992px) and (max-width: 1354px) {
          padding:0 $xs;
          width:16.5%;
        }

        /* Portrait tablets and small desktops */
        @media (min-width: 768px) and (max-width: 991px) {
          padding:0 $xs;
          width:16.5%;
        }

        /* Landscape phones and portrait tablets */
        @media (max-width: 767px) {

        }

        /* Portrait phones and smaller */
        @media (max-width: 480px) {
            width: 100%;
            padding: 0 20px;
        }
      }
    .t-t-filter-desembarque {
     /* @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.9, $gutter: 20px);
      @include make-lg-column(1.4, $gutter: 20px);*/

      @media (min-width: 1355px) {
        padding: 0 5px;
        width:16.5%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
          width: 100%;
          padding: 0 20px;
      }
    }

    .t-t-filter-destino {
     /* @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.9, $gutter: 20px);
      @include make-lg-column(1.2, $gutter: 20px); */
      @media (min-width: 1355px) {
        padding: 0 5px;
        width:16.5%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
          width: 100%;
          padding: 0 20px;
      }
    }
    .t-t-filter-periodo {
     /* @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.9, $gutter: $md);
      @include make-lg-column(1, $gutter: $md);
      */
      @media (min-width: 1355px) {
        padding: 0 5px;
        width:16.5%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {
        width: 33%;
      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
        width: 33%;

      }
      border-right: 1px solid #e4e4e4 !important;
    }
    .t-t-filter-inicio {

      @media (min-width: 1355px) {
        padding: 0 5px;
        width:16.5%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {
        width: 33%;

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
        width: 33%;

      }

      display: inline-block !important;
      .datepicker-input-container,
      .datepicker-container,
      .datepicker-input {
       /* width: 83px !important;*/
      }
    }
    .t-t-filter-fim {
      display: inline-block;
      border-right:none;

      height: 60px !important;
      text-align: left !important;
      font-family: "Open Sans", "Helvetica", "Calibri", sans-serif !important;
      font-weight: 700 !important;
      font-size: 13px !important;
      color: #8a8a8a !important;
      span{
        line-height: 38px;
        vertical-align: middle;
      }
      @media (min-width: 1355px) {
        padding: 0 5px;
        width:16.5%;
      }

      /* Landscape tablets and medium desktops */
      @media (min-width: 992px) and (max-width: 1354px) {
        padding:0 $xs;
        width:16.5%;
      }

      /* Portrait tablets and small desktops */
      @media (min-width: 768px) and (max-width: 991px) {
        padding:0 $xs;
        width:17.5%;
        border-bottom: 1px solid lighten($search-label-color, 35%);
      }

      /* Landscape phones and portrait tablets */
      @media (max-width: 767px) {
        width: 33%;

      }

      /* Portrait phones and smaller */
      @media (max-width: 480px) {
              width: 33%;

      }


    }
    .search-action-tracking{
      .btn-search {
        @media (min-width: 1355px) {
          height: 120px;
        }

      }
   }
  } // FINAL HAS PERIODO

  .customer-profile {
    .t-t-filter-cnpj {
      display: none !important;
    }
    .t-t-filter-number {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(3, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
    }
    .t-t-filter-navio-viagem {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(2.3, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
      .form-control {
        margin-left: 1px !important;
        height: 55px;
      }
    }
    .t-t-filter-origem {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.7, $gutter: 20px);
      @include make-lg-column(1.7, $gutter: 20px);
    }
    .t-t-filter-embarque {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.7, $gutter: 20px);
      @include make-lg-column(1.7, $gutter: 20px);
    }
    .t-t-filter-destino {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.7, $gutter: 20px);
      @include make-lg-column(1.7, $gutter: 20px);
    }
    .t-t-filter-desembarque {
      @include make-xs-column(6, $gutter: 20px);
      @include make-sm-column(3, $gutter: 20px);
      @include make-md-column(1.7, $gutter: 20px);
      @include make-lg-column(1.7, $gutter: 20px);
    }
    .t-t-filter-periodo {
      @include make-xs-column(12, $gutter: 20px);
      @include make-sm-column(6, $gutter: 20px);
      @include make-md-column(1.5, $gutter: 20px);
      @include make-lg-column(1.5, $gutter: 20px);
      border-right: 0 ;
    }
    .t-t-filter-inicio {
      border-right: 0;
    }
  }

  // INICIO DEFAULT
  .t-t-filter-cnpj {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
    border-right: 1px solid #e4e4e4 !important;
    .form-control {
      margin-left: 1px !important;
    }
  }
  .t-t-filter-number {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(2.5, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.3, $gutter: 20px);
    .form-control {
      margin-left: 1px !important;
      height: 55px;
      padding-top: 10px;
    }
  }
  .t-t-filter-navio-viagem {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(2, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    .form-control {
      margin-left: 1px !important;
      height: 55px;
    }
  }
  .t-t-filter-origem {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(2, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.3, $gutter: 20px);
  }
  .t-t-filter-embarque {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.3, $gutter: 20px); // @media (min-width: $tablet) and (max-width: $desktop) {
    // 	border-right: 0;
    // }
  }
  .t-t-filter-destino {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }
  .t-t-filter-desembarque {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.4, $gutter: 20px);
  }
  .t-t-filter-periodo {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
    border-right: 0;
  }
  .t-t-filter-inicio {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
    display: none !important;
    .datepicker-container,
    .datepicker-input,
    .datepicker-input-container {
      width: 90px;
      @media (max-width: $tablet) {
        width: 110px !important;
      }
    }
  }
 // FINAL DEFAULT
 .search-action-tracking{
  .btn-search {
    @media (min-width: 769px) and (max-width: 1199px) {
      height: 120px;
    }
  }
}
  /*
  .search-action-tracking {
  // @include make-xs-column(12, $gutter: 20px);
   // @include make-sm-column(12, $gutter: 20px);
   // @include make-md-column(0.8, $gutter: 20px);
  //  @include make-lg-column(0.8, $gutter: 20px);
    width: 10%;

    float: right !important;
    @media screen and (min-width: $tablet-landscape) {
      max-width: 60px;
    }

  }
  .search-action-tracking {

    @media screen and (max-width: $tablet-landscape) {
      margin-top: 0;
    }
    .btn-search {
      @media screen and (max-width: 767px) {
        width: 98%;
        min-height: inherit;
        margin: $xs;
        border-radius: 4px;
      }
      @media (min-width: $tablet-landscape) and (max-width: $desktop) {
        border-top-left-radius: 0;
      }

      @media (min-width: $tablet-landscape) and (max-width: $desktop) {
        min-height: 120px;

      }
    }
    @media (min-width: $tablet) and (max-width: 992px) {
      position: relative;
      right: -2px;
    }
    @media (min-width: 768px) {
      position: absolute;
      border-top-right-radius: 0;
      right: 0px;
    }
  }
  */
  .t-t-filter-cnpj,
  .t-t-filter-desembarque,
  .t-t-filter-destino,
  .t-t-filter-embarque,

  .t-t-filter-inicio,
  .t-t-filter-number,
  .t-t-filter-origem,
  .t-t-filter-periodo {
   /* max-width: inherit !important;*/
    display: inline-block;
    min-height: 60px !important;

      border-bottom: 1px solid lighten($search-label-color, 35%);

    .select-group {
      min-height: 60px !important;
    }
  }
  .t-t-filter-cnpj {
    @media (max-width: $tablet) {
      border-right: 0;
    }
  }
  .t-t-filter-destino,
  .t-t-filter-origem,
   {
    @media (max-width: $tablet) {
      border-right: 1px solid #e4e4e4 !important;
    }
  }
}
