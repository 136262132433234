 .main-search.main-cotacao{
 // INICIO DEFAULT
  .t-t-filter-cnpj {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
    border-right: 1px solid #e4e4e4 !important;
    .form-control {
      margin-left: 1px !important;
    }
  }
  .t-t-filter-number {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(2.5, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.3, $gutter: 20px);
    .form-control {
      margin-left: 1px !important;
      height: 55px;
      padding-top: 10px;
    }
  }
  .t-t-filter-navio-viagem {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(2, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.5, $gutter: 20px);
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    .form-control {
      margin-left: 1px !important;
      height: 55px;
    }
  }
  .t-t-filter-origem {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(2, $gutter: 20px);
    @include make-md-column(3, $gutter: 20px);
    @include make-lg-column(1.3, $gutter: 20px);
  }
  .t-t-filter-embarque {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.3, $gutter: 20px); // @media (min-width: $tablet) and (max-width: $desktop) {
    // 	border-right: 0;
    // }
  }
  .t-t-filter-destino {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
  }
  .t-t-filter-desembarque {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.4, $gutter: 20px);
  }
  .t-t-filter-periodo {
    @include make-xs-column(12, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
    border-right: 0;
  }
  .t-t-filter-inicio {
    @include make-xs-column(6, $gutter: 20px);
    @include make-sm-column(3, $gutter: 20px);
    @include make-md-column(2.3, $gutter: 20px);
    @include make-lg-column(1.2, $gutter: 20px);
    display: none !important;
    .datepicker-container,
    .datepicker-input,
    .datepicker-input-container {
      width: 90px;
      @media (max-width: $tablet) {
        width: 110px !important;
      }
    }
  }
  
}