.panel-register {
 .control-label{

  text-align: left !important;

}
  .register-message {
    font-family: $font-2;
    font-size: em(22px);
    color: #586163;
    text-align: center;
    margin: $lg 0 $md;
  }

  .row {
    padding: $sm 0;
    @media screen and (min-width: 768px)  {
     
      margin: $md;
    }
    border-bottom: 1px solid #edeeee;
  }

  // .alert-danger {
  //   padding: $xs 0 0;
  //   margin-bottom: 0;
  // }

  .custom-checkbox {
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      padding-left: 45px !important;
      font-size: em(14px);
      font-weight: normal;
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      border-radius: $border-radius-base;
      position: absolute;
      top: -10px;
      left: 0;
      width: 36px;
      height: 36px;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      font-size: em(20px);
      padding-top: 3px !important;
    }
  }
}
