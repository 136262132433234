$footer-bg-color: #fff;
$footer-link-color: darken($topnav-bg-color, 20%);
$footer-font-size: em(11px);
$footer-font-size-mobile: em(9px);

.main-footer-alianca {
  background-color: $footer-bg-color;
  margin: 0;

  // position: absolute;
  // right: 0;
  // bottom: 0px;
  // left: 0;

  .nav {
    text-align: center;

    @media screen and (max-width: $tablet-landscape) {
      margin: $md 0;
    }

    > li {
      display: inline-block;

      @media screen and (max-width: $tablet-landscape) {
        width: 100%;
        display: block;
      }
    }

    > li > a {
      display: inline-block;
      color: $footer-link-color;
      font-size: $footer-font-size;
      text-transform: uppercase;
      padding: $lg $md;
      font-family: $font-3;
      font-weight: 400;
      transition: all 0.25s ease-in-out 0s;

      @media screen and (max-width: $tablet-landscape) {
        width: 100%;
        padding: $sm 0;
        text-align: center;
        font-size: $footer-font-size-mobile;
      }
    }
  }
}
